import { useAppContext } from 'containers/App/AppContext';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Axios from 'utils/axios';
import { ENDPOINTS, ROUTES } from 'utils/constants';
import { errorHandler } from 'utils/helper';

const useLogin = (googleToken: string) => {
  const history = useHistory();
  const { setGlobalError, setGlobalLoading } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchingError, setFetchingError] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      if (googleToken === '') return;
      setLoading(true);
      setGlobalLoading(true);

      try {
        await Axios.post(
          ENDPOINTS.LOGIN,
          { auth_code: googleToken },
          { withCredentials: true },
        );

        setLoading(false);

        history.push(ROUTES.HOME);
      } catch (error) {
        errorHandler(error, setGlobalError);

        setFetchingError(error.toString());
        setLoading(false);
        setGlobalLoading(false);
      }
    };

    fetchData();
  }, [googleToken, history, setGlobalError, setGlobalLoading]);

  return { loading, fetchingError };
};

export default useLogin;
