import { useEffect, useState } from 'react';

import { ExerciseHistories } from 'types';

const useSortHistoryData = (exerciseHistories: ExerciseHistories[]) => {
  const [sort, setSort] = useState('desc');
  const [sortedExercisesHistoryData, setSortedExercisesHistoryData] = useState<
    ExerciseHistories[]
  >();

  useEffect(() => {
    if (sort === 'asc') {
      const sortedExercisesHistoryData = exerciseHistories
        .slice()
        .sort(
          (a, b) =>
            new Date(a.exercise_date).getTime() -
            new Date(b.exercise_date).getTime(),
        );

      setSortedExercisesHistoryData(sortedExercisesHistoryData);
    } else if (sort === 'desc') {
      const sortedExercisesHistoryData = exerciseHistories
        .slice()
        .sort(
          (a, b) =>
            new Date(b.exercise_date).getTime() -
            new Date(a.exercise_date).getTime(),
        );

      setSortedExercisesHistoryData(sortedExercisesHistoryData);
    }
  }, [sort, exerciseHistories]);
  return { sortedExercisesHistoryData, setSort, sort };
};

export default useSortHistoryData;
