import { useEffect, useState } from 'react';

import { PointHistories } from 'types';

const useSortHistoryData = (pointHistories: PointHistories[]) => {
  const [sort, setSort] = useState('desc');
  const [sortedPointsHistoryData, setSortedPointsHistoryData] = useState<
    PointHistories[]
  >();

  useEffect(() => {
    if (sort === 'asc') {
      const sortedPointsHistoryData = pointHistories
        .slice()
        .sort(
          (a, b) =>
            new Date(a.grant_date).getTime() - new Date(b.grant_date).getTime(),
        );

      setSortedPointsHistoryData(sortedPointsHistoryData);
    } else if (sort === 'desc') {
      const sortedPointsHistoryData = pointHistories
        .slice()
        .sort(
          (a, b) =>
            new Date(b.grant_date).getTime() - new Date(a.grant_date).getTime(),
        );

      setSortedPointsHistoryData(sortedPointsHistoryData);
    }
  }, [sort, pointHistories]);
  return { sortedPointsHistoryData, setSort, sort };
};

export default useSortHistoryData;
