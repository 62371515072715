import React from 'react';

import { Dimmer, Loader } from 'semantic-ui-react';

const InlineLoader: React.FC = () => {
  return (
    <Dimmer active data-testid="dimmer" style={{ height: '100vh' }}>
      <Loader data-testid="loader" />
    </Dimmer>
  );
};

export default InlineLoader;
