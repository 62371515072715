import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import AppContextProvider from 'containers/App/AppContext';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import history from 'utils/history';

Bugsnag.start({
  apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
  plugins: [new BugsnagPluginReact(React)],
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <AppContextProvider>
      <Router history={history}>
        <App />
      </Router>
    </AppContextProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
