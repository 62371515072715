import React from 'react';
import { Header, Image, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

const PointsHistoryLink: React.FunctionComponent = () => {
  return (
    <Segment
      color="purple"
      data-testid="PointsHistoryLink"
      className="PointsHistoryLink"
      inverted
      as={Link}
      to={ROUTES.POINTS_HISTORY}
    >
      <Header
        as="h5"
        data-testid="PointsHistoryLink__label"
        className="PointsHistoryLink__label"
        color="pink"
      >
        付与履歴
        <br />
        <span className="PointsHistoryLink__label__sub">
          Lịch sử phân bổ
          <br />
          The history for allocation
        </span>
        を見る
      </Header>
      <Image
        src={`${process.env.PUBLIC_URL}/icons/arrow@3x.png`}
        data-testid="PointsHistoryLink__icon"
        className="PointsHistoryLink__icon"
        inline
        height="16"
      ></Image>
    </Segment>
  );
};

export default PointsHistoryLink;
