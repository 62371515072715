import React from 'react';
import { Container, Header, Image } from 'semantic-ui-react';

type ErrorPageProps = {
  errorCode?: number;
  errorMessage?: string;
};

const ErrorPage: React.FC<ErrorPageProps> = ({
  errorCode = 404,
  errorMessage = 'お探しのページが見つかりません',
}) => {
  return (
    <Container data-testid="NotFoundPage" className="NotFoundPage">
      <Header
        as="h1"
        color="blue"
        data-testid="NotFoundPage__header"
        className="NotFoundPage__header"
      >
        {errorCode === 404 ? (
          <>
            <span>4</span>
            <Image
              src={`${process.env.PUBLIC_URL}/icons/Sad_boy@3x.png`}
              inline
              alt="0"
            />
            <span>4</span>
          </>
        ) : (
          <span data-testid="NotFoundPage_errorCode">{errorCode}</span>
        )}
      </Header>
      <Header
        as="h4"
        color="blue"
        data-testid="NotFoundPage__message"
        className="NotFoundPage__message"
      >
        {errorMessage}
      </Header>
    </Container>
  );
};

export default ErrorPage;
