import React from 'react';
import { Container, Header, Grid, Image } from 'semantic-ui-react';
import CassetteInfoTransaction from 'components/molecules/CassetteInfoTransaction';
import CassetteInfoFooter from 'components/molecules/CassetteInfoFooter';
import CassetteInfoMassage from 'components/molecules/CassetteInfoMassage';
import InlineLoader from 'components/atoms/InlineLoader';
import useGetCassetteInfo from './useGetCassetteInfo';
import { getCurrentDateEn } from 'utils/helper';

const CassetteInfoPage: React.FunctionComponent = () => {
  const { loading, CassetteInfoData } = useGetCassetteInfo();

  return (
    <>
      {loading ? (
        <InlineLoader />
      ) : (
        <Container
          data-testid="CassetteInfoPage"
          id="CassetteInfoPage"
          className="CassetteInfoPage"
        >
          <Grid
            className="CassetteInfo__Grid FullWidthGrid NoMargins"
            padded={false}
          >
            <Grid.Row centered>
              <Grid.Column
                mobile={14}
                tablet={8}
                computer={8}
                largeScreen={6}
                widescreen={4}
              >
                <Header
                  as="h3"
                  textAlign="left"
                  className="CassetteInfo__header"
                  data-testid="CassetteInfo__header"
                >
                  各信託別情報
                  <br />
                  <div className="CassetteInfo_SubTitle">
                    Thông tin của các Ủy thác
                    <br />
                    Information for each Trust
                  </div>
                </Header>
                <Image
                  src={`${process.env.PUBLIC_URL}/icons/Sunpoint_red@3x.png`}
                  centered
                  data-testid="PointsCassette__icon"
                  className="mgBottomSmall"
                  height="62"
                  width="62"
                ></Image>
                <Header
                  as="h5"
                  data-testid="HomePage__current-date"
                  className="HomePage__current-date"
                  color="red"
                >
                  {getCurrentDateEn()}
                </Header>
                {CassetteInfoData.show_share_option === false && (
                  <>
                    <CassetteInfoMassage />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
            {CassetteInfoData.show_share_option === true && (
              <>
                <Grid.Row centered>
                  <Grid.Column
                    mobile={14}
                    tablet={8}
                    computer={8}
                    largeScreen={5}
                    widescreen={3}
                  >
                    <div className="CassetteInfo__Label__bold">
                      {getCurrentDateEn()}時点であなたは
                      <br />
                      Sun*の株を1株80円で
                    </div>
                    <div className="CassetteInfoTotal">
                      {CassetteInfoData.total}
                      <span className="CassetteInfo__Subscript">株</span>
                    </div>
                    <div className="CassetteInfo__Label__bold">
                      <br />
                      購入できます <br />
                    </div>
                    <div className="CassetteInfo__notice">
                      Tại thời điểm {getCurrentDateEn()}, bạn có thể mua <br />
                      cổ phiếu của Sun * với giá 80 Yên/cổ phiếu{' '}
                      {CassetteInfoData.total}.
                      <br />
                      <br />
                      At {getCurrentDateEn()}, you can purchase Sun* shares
                      <br />
                      for 80 JPY per share {CassetteInfoData.total}.
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row centered>
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    largeScreen={5}
                    widescreen={3}
                  >
                    {CassetteInfoData.cassette.map((cassette, index) => {
                      return (
                        <>
                          {cassette && (cassette.exists || index < 3) && (
                            <CassetteInfoTransaction
                              cassette={cassette}
                            ></CassetteInfoTransaction>
                          )}
                        </>
                      );
                    })}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered style={{ paddingBottom: 0 }}>
                  <Grid.Column
                    mobile={16}
                    tablet={10}
                    computer={9}
                    largeScreen={5}
                    widescreen={5}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <CassetteInfoFooter />
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
          </Grid>
        </Container>
      )}
    </>
  );
};

export default CassetteInfoPage;
