import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import LoginForm from 'components/molecules/LoginForm';
import ResetPasswordLink from 'components/molecules/ResetPasswordLink';
import 'index.css';

import useGuestLogin from './useGuestLogin';
import ReturnLink from 'components/atoms/ReturnLink';

const GuestLogin: React.FC = () => {
  const { loading, setLoginCredentials } = useGuestLogin();

  return (
    <>
      <Container data-testid="GuestLogin" className="LoginPage">
        <Grid
          padded
          centered
          columns={1}
          verticalAlign="middle"
          className="FullWidthGrid"
        >
          <Grid.Row>
            <Grid.Column
              mobile={14}
              tablet={8}
              computer={6}
              largeScreen={4}
              widescreen={3}
            >
              <LoginForm
                loading={loading}
                setLoginCredentials={setLoginCredentials}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              mobile={14}
              tablet={8}
              computer={6}
              largeScreen={4}
              widescreen={3}
            >
              <ResetPasswordLink />
              <p className="LoginForm__resetPassword__label">
                初めて使用する際やパスワードを忘れた
                <br />
                などで設定する方はこちらに進んでくだ
                <br />
                さい。
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ReturnLink />
      </Container>
    </>
  );
};

export default GuestLogin;
