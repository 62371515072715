import {
  ProfileState,
  UserState,
  CassetteInfoState,
  PointsHistoryState,
  ExercisesHistoryState,
} from 'types';

export enum ENDPOINTS {
  LOGIN = '/auth/google/login',
  GUEST_LOGIN = '/auth/email/login',
  PASSWORD_RESET_REQUEST = '/user/password/reset_request',
  CHECK_TOKEN = '/user/password/verify_token',
  PASSWORD_RESET = '/user/password',
  LOGOUT = '/auth/logout',
  PROFILE = '/me',
  HOME = '/home',
  HISTORY = '/me/point_history',
  E_HISTORY = '/me/exercise_history',
  CASSETTE_INFO = '/me/share_option',
}

export enum ROUTES {
  GUEST_LOGIN = '/login',
  LOGIN = '/',
  HOME = '/home',
  POINTS_HISTORY = '/points-history',
  EXERCISES_HISTORY = '/exercises-history',
  CASSETTE_INFO = '/cassette-info',
  PROFILE = '/profile',
  RESET_PASSWORD = '/reset-password',
  SET_PASSWORD = '/set-password',
  PASSWORD_RESET_SUCCESSFULLY = '/set-password-successfull',
}

export enum EXTERNAL_LINK {
  FINANCE = 'https://shikiho.jp/stocks/4053/',
}

export enum POINTS_SOURCE_COLOR {
  SUN_ANNIVERSARY = '#7F58AF',
  SIGN_UP = '#7F58AF',
  REFERRAL = '#7F58AF',
  BIZ_DEV = '#7F58AF',
  ASTERISK = '#7F58AF',
  ROUNDING = '#7F58AF',
}

export enum POINTS_SOURCE_STRING {
  SUN_ANNIVERSARY = 'SOポイント',
  SIGN_UP = 'SOポイント',
  REFERRAL = 'SOポイント',
  BIZ_DEV = 'SOポイント',
  ASTERISK = 'SOポイント',
  ROUNDING = '端数調整',
}

export enum LANGUAGES {
  JAPANESE = 'japanese',
  VIETNAMESE = 'Vietnamese',
  ENGLISH = 'english',
}

export enum COLORS {
  SLATE_BLUE = '#545B9B',
}

export enum VALUE {
  STOCK_UNIT = 20,
}

export const PROFILE_DATA: ProfileState = {
  id: '',
  name: '',
  hired_at: '',
  email: '',
};

export const CASSETTE_INFO_DATA: CassetteInfoState = {
  id: 0,
  total: 0,
  show_share_option: true,
  cassette: [
    {
      name: '',
      exists: true,
      remainig_points: 0,
      maximum_exercise_stocks: 0,
      exercise_start_date: '',
      limit_change_date: '',
    },
  ],
};

export const USER_DATA: UserState = {
  points: 0,
  name: '',
  cassette: [{ name: '', points: 0, share_option: true, exists: true }],
};

export const DEFAULT_POINT_HISTORY_ID = 0;

export const POINTS_HISTORY_DATA: PointsHistoryState = {
  points: 0,
  point_histories: [
    {
      id: DEFAULT_POINT_HISTORY_ID,
      grant_date: '',
      point_amount: 0,
      point_type: 'referral',
      cassette_name: 'カセットA',
    },
  ],
};

export const EXERCISE_HISTORY_DATA: ExercisesHistoryState = {
  exercise_histories: [
    {
      id: DEFAULT_POINT_HISTORY_ID,
      exercise_date: '',
      exercise_amount: 0,
      cassette_name: 'カセットA',
    },
  ],
};

export const DEFAULT_APP_STATE = {
  userData: USER_DATA,
  setUserData: () => USER_DATA,
  globalError: {},
  setGlobalError: () => null,
  globalLoading: true,
  setGlobalLoading: () => true,
};

export const SOURCE_STRING = {
  //TODO: refferal will change to referral so can be removed later
  refferal: POINTS_SOURCE_STRING.REFERRAL,
  referral: POINTS_SOURCE_STRING.REFERRAL,
  //TODO: enter will change to sign_up so can be removed later
  enter: POINTS_SOURCE_STRING.SIGN_UP,
  //TODO: signup will change to sign_up so can be removed later
  signup: POINTS_SOURCE_STRING.SIGN_UP,
  sign_up: POINTS_SOURCE_STRING.SIGN_UP,
  anniversary: POINTS_SOURCE_STRING.SUN_ANNIVERSARY,
  biz_dev: POINTS_SOURCE_STRING.BIZ_DEV,
  asterisk: POINTS_SOURCE_STRING.ASTERISK,
  rounding: POINTS_SOURCE_STRING.ROUNDING,
};

export const SOURCE_DICTIONARY = {
  //TODO: refferal will change to referral so can be removed later
  refferal: POINTS_SOURCE_COLOR.REFERRAL,
  referral: POINTS_SOURCE_COLOR.REFERRAL,
  //TODO: enter will change to sign_up so can be removed later
  enter: POINTS_SOURCE_COLOR.SIGN_UP,
  //TODO: signup will change to sign_up so can be removed later
  signup: POINTS_SOURCE_COLOR.SIGN_UP,
  sign_up: POINTS_SOURCE_COLOR.SIGN_UP,
  anniversary: POINTS_SOURCE_COLOR.SUN_ANNIVERSARY,
  biz_dev: POINTS_SOURCE_COLOR.BIZ_DEV,
  asterisk: POINTS_SOURCE_COLOR.ASTERISK,
  rounding: POINTS_SOURCE_COLOR.ROUNDING,
};

export enum ALERT_STATES {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum ERROR_CODES {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  SERVER_ERROR = 500,
  SERVER_UNAVAILABLE = 503,
}

export const BIG_MARGIN = '2rem';

export const VALID_EMAIL_CHECK_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const VALID_PASSWORD_CHECK_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

export const INVALID_EMAIL_ERROR_MESSAGE =
  '有効なメールアドレスを入力してください';
export const INVALID_PASSWORD_ERROR_MESSAGE =
  '8文字以上、半角のみ大文字・小文字のアルファベット、数字をそれぞれ1文字以上含めてください';
