import React from 'react';
import { Header, Image, Segment } from 'semantic-ui-react';
import { EXTERNAL_LINK } from 'utils/constants';

const FinanceLink: React.FunctionComponent = () => {
  return (
    <Segment
      // TODO: colorの色味が、figmaデザインの色と異なるがSemanticCOLORSで定義されていないので対応が必要
      data-testid="FinanceLink"
      className="FinanceLink"
      inverted
      href={EXTERNAL_LINK.FINANCE}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Header
        as="h5"
        data-testid="FinanceLink__label"
        className="FinanceLink__label"
        // TODO: colorの色味が、figmaデザインの色と異なるがSemanticCOLORSで定義されていないので対応が必要
      >
        Sun*のファイナンス情報を見に行く
      </Header>
      <Image
        src={`${process.env.PUBLIC_URL}/icons/arrow_yellow@3x.png`}
        data-testid="FinanceLink__icon"
        className="FinanceLink__icon"
        inline
        height="16"
      ></Image>
    </Segment>
  );
};

export default FinanceLink;
