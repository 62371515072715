import React from 'react';
import { Header } from 'semantic-ui-react';

type ProfileLabelProps = {
  label: string;
};

const ProfileLabel: React.FunctionComponent<ProfileLabelProps> = ({
  label,
}) => {
  return (
    <Header
      as="h5"
      color="green"
      textAlign="left"
      className="ProfileContainer__label"
      data-testid="ProfileContainer__label"
    >
      {label}
    </Header>
  );
};

export default ProfileLabel;
