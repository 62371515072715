import React, { useEffect, useState } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import SetPasswordForm from 'components/molecules/SetPassword';
import { useHistory, useLocation } from 'react-router-dom';
import useCheckToken from './useCheckToken';
import { ROUTES } from 'utils/constants';
import InlineLoader from 'components/atoms/InlineLoader';
import useSetPassword from './useSetPassword';
import ReturnLink from 'components/atoms/ReturnLink';

const SetPassword: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    const matches = location.search.match('/?token=(.*)');
    const urlToken = matches && matches.length > 1 ? matches[1] : null;

    if (urlToken) {
      setToken(urlToken ? urlToken : '');
    } else {
      history.push(ROUTES.LOGIN);
    }
  }, [history, location.search]);

  const { loading, fetchingError } = useCheckToken(token);
  const {
    setPasswords,
    loading: loadingSetPassword,
    successFullResponse,
  } = useSetPassword({ password: '', password_confirmation: '' }, token);

  useEffect(() => {
    if (successFullResponse) {
      history.push(ROUTES.PASSWORD_RESET_SUCCESSFULLY);
    }
  }, [successFullResponse, history]);

  useEffect(() => {
    if (fetchingError) {
      history.push(ROUTES.LOGIN);
    }
  }, [fetchingError, history]);

  return (
    <>
      {loading ? (
        <InlineLoader />
      ) : (
        <Container data-testid="SetPassword" className="SetPassword">
          <Grid padded centered columns={1} verticalAlign="middle">
            <Grid.Row>
              <Grid.Column
                mobile={14}
                tablet={14}
                computer={14}
                largeScreen={14}
                widescreen={14}
              >
                <SetPasswordForm
                  loading={loadingSetPassword}
                  setPasswords={setPasswords}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <ReturnLink />
        </Container>
      )}
    </>
  );
};
export default SetPassword;
