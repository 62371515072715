import React from 'react';
import { Image } from 'semantic-ui-react';

const AppLogo: React.FunctionComponent = () => {
  return (
    <div
      data-testid="LoginPage__logo"
      className="LoginPage__logo LoginPage__logo--left"
    >
      <Image
        data-testid="AppLogoImage"
        src={`${process.env.PUBLIC_URL}/images/logo@3x.png`}
        inline
      ></Image>
      <p className="AppLogoLabel">PLAY-FUN, WORK-FUN, SO-FUN</p>
    </div>
  );
};

export default AppLogo;
