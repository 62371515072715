import { useState, useEffect } from 'react';

import { useAppContext } from 'containers/App/AppContext';
import Axios from 'utils/axios';
import { ENDPOINTS } from 'utils/constants';
import { useLocation } from 'react-router-dom';

import { errorHandler, isGuestRouteExceptLogin } from 'utils/helper';

const useGetHome = () => {
  const location = useLocation();
  const {
    userData,
    setUserData,
    setGlobalLoading,
    setGlobalError,
  } = useAppContext();

  const [fetchingError, setFetchingError] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      if (isGuestRouteExceptLogin(location.pathname) || userData.name !== '') {
        setGlobalLoading(false);
        return;
      }

      setGlobalLoading(true);
      setGlobalError({});

      try {
        const response = await Axios.get(ENDPOINTS.HOME, {
          withCredentials: true,
        });

        setUserData(response.data);

        setGlobalLoading(false);
      } catch (error) {
        errorHandler(error, setGlobalError);
        setFetchingError(error.toString());
        setGlobalLoading(false);
      }
    };

    fetchData();
  }, [
    setGlobalLoading,
    setUserData,
    userData.name,
    location.pathname,
    setGlobalError,
  ]);

  return { userData, fetchingError };
};

export default useGetHome;
