import React from 'react';
import { Container, Header, Image, Grid } from 'semantic-ui-react';

import 'index.css';
import useGetHistory from './useGetHistory';
import ExercisesTransaction from 'components/molecules/ExercisesTransaction';
import InlineLoader from 'components/atoms/InlineLoader';

import useSortHistoryData from './useSortHistoryData';
import { verify_E_HistoryData } from 'utils/helper';
import { getCurrentDateEn } from 'utils/helper';

const ExercisesTransactions: React.FunctionComponent = () => {
  const { loading, exercisesHistoryData } = useGetHistory();

  const { sortedExercisesHistoryData, sort, setSort } = useSortHistoryData(
    exercisesHistoryData.exercise_histories,
  );

  return (
    <>
      {loading ? (
        <InlineLoader />
      ) : (
        <Container
          data-testid="ExercisesTransactions"
          className="ExercisesTransactions"
        >
          <Grid>
            <Grid.Row centered>
              <Grid.Column
                mobile={14}
                tablet={8}
                computer={8}
                largeScreen={6}
                widescreen={4}
              >
                {sort === 'asc' && (
                  <Image
                    src={`${process.env.PUBLIC_URL}/icons/datepicker_new@3x.png`}
                    centered
                    data-testid="PointsTransactions__datepicker-icon-up"
                    className="PointsSummary__datepicker-icon"
                    width="29"
                    onClick={() => setSort('desc')}
                  ></Image>
                )}

                {sort === 'desc' && (
                  <Image
                    src={`${process.env.PUBLIC_URL}/icons/datepicker_old@3x.png`}
                    centered
                    data-testid="PointsTransactions__datepicker-icon-down"
                    className="PointsSummary__datepicker-icon"
                    width="29"
                    onClick={() => setSort('asc')}
                  ></Image>
                )}
                <Header
                  as="h3"
                  data-testid="ExercisesTransactions__header"
                  className="ExercisesTransactions__header"
                >
                  行使履歴
                </Header>
                <span data-testid="SubTitle" className="SubTitle">
                  <strong>Lịch sử thực hiện | Exercise history</strong>
                </span>
                <Image
                  src={`${process.env.PUBLIC_URL}/icons/Sunpoint_red@3x.png`}
                  centered
                  data-testid="ExercisesTransactions__point-mark"
                  className="ExercisesTransactions__point-mark"
                  height="60"
                  width="60"
                ></Image>
                <Header
                  as="h5"
                  data-testid="ExercisesTransactions__current-date"
                  className="ExercisesTransactions__current-date"
                  color="red"
                >
                  {getCurrentDateEn()}
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid className="ExercisesTransactions__transactions" stretched>
            {sortedExercisesHistoryData &&
              verify_E_HistoryData(sortedExercisesHistoryData) &&
              sortedExercisesHistoryData.map((transaction, index) => (
                <>
                  <Grid.Row
                    stretched
                    centered
                    key={transaction.id}
                    className="ExercisesTransactions__transactions_item"
                  >
                    <Grid.Column
                      mobile={16}
                      tablet={10}
                      computer={9}
                      largeScreen={6}
                      widescreen={4}
                    >
                      <ExercisesTransaction
                        index={index}
                        exercise_date={transaction.exercise_date}
                        exercise_amount={transaction.exercise_amount}
                        cassette_name={transaction.cassette_name}
                      ></ExercisesTransaction>
                    </Grid.Column>
                  </Grid.Row>
                </>
              ))}
          </Grid>
        </Container>
      )}
    </>
  );
};

export default ExercisesTransactions;
