import React from 'react';
import { Image, Sidebar, Menu } from 'semantic-ui-react';

import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
type SidebarProps = {
  setLogoutClicked: React.Dispatch<React.SetStateAction<boolean>>;
  hideSidebar: () => void;
  visible: boolean;
};

const NavigationSidebar: React.FunctionComponent<SidebarProps> = ({
  setLogoutClicked,
  hideSidebar,
  visible,
}) => {
  return (
    <Sidebar
      id="Sidebar"
      as={Menu}
      className="Sidebar"
      data-testid="Sidebar"
      animation="overlay"
      vertical
      visible={visible}
      width="thin"
      onHide={() => {
        if (visible) hideSidebar();
      }}
    >
      <Menu.Item
        as={Link}
        className="Sidebar__item"
        to={ROUTES.HOME}
        onClick={() => hideSidebar()}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/icons/home.png`}
          height="21"
          width="21"
          inline
        ></Image>
        <span className="Sidebar__item__label">
          ダッシュボード
          <br />
        </span>
        <span className="Sidebar__item__sublabel">
          Dảng điều khiển
          <br />
        </span>
        <span className="Sidebar__item__sublabel">Dashboard</span>
      </Menu.Item>
      <Menu.Item
        as={Link}
        className="Sidebar__item"
        to={ROUTES.POINTS_HISTORY}
        onClick={() => hideSidebar()}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/icons/point_history.png`}
          height="21"
          width="14.3"
          inline
        ></Image>
        <span className="Sidebar__item__label">
          付与履歴
          <br />
        </span>
        <span className="Sidebar__point__sublabel_">Lịch sử phân bổ </span>
        <br />
        <span className="Sidebar__point__sublabel_">The history for </span>
        <br />
        <span className="Sidebar__point__sublabel_">allocation </span>
      </Menu.Item>
      <Menu.Item
        as={Link}
        className="Sidebar__item"
        to={ROUTES.EXERCISES_HISTORY}
        onClick={() => hideSidebar()}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/icons/exercise_history.png`}
          height="14.3"
          width="21"
          inline
        ></Image>
        <span className="Sidebar__item__label">
          行使履歴
          <br />
        </span>
        <span className="Sidebar__item__sublabel">Lịch sử thực hiện</span>
        <br />
        <span className="Sidebar__item__sublabel">Exercise history </span>
      </Menu.Item>
      <Menu.Item
        as={Link}
        className="Sidebar__item"
        to={ROUTES.CASSETTE_INFO}
        onClick={() => hideSidebar()}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/icons/cassette_info.png`}
          height="21"
          width="21"
          inline
        ></Image>
        <span className="Sidebar__item__label">
          各信託別情報
          <br />
        </span>
        <span className="Sidebar__item__sublabel">Thông tin băng </span>
        <span className="Sidebar__item__sublabel">Cassette Info </span>
      </Menu.Item>
      <Menu.Item
        as={Link}
        className="Sidebar__item"
        to={ROUTES.PROFILE}
        onClick={() => hideSidebar()}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/icons/user.png`}
          height="21"
          width="21"
          inline
        ></Image>
        <span className="Sidebar__item__label">
          プロフィール
          <br />
        </span>
        <span className="Sidebar__item__sublabel">Hồ sơ | Profile</span>
      </Menu.Item>
      <Menu.Item
        as="a"
        data-testid="LogoutButton"
        className="Sidebar__item"
        onClick={() => setLogoutClicked(true)}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/icons/logout@3x.png`}
          height="21"
          width="21"
          inline
        ></Image>
        <span className="Sidebar__item__label">
          ログアウト
          <br />
        </span>
        <span className="Sidebar__item__sublabel">
          Đăng xuất <br />
        </span>
        <span className="Sidebar__item__sublabel">Logout</span>
      </Menu.Item>
    </Sidebar>
  );
};

export default NavigationSidebar;
