import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import HomePage from 'components/pages/Home';
import PointsTransactions from 'components/pages/PointsTransactions';
import ExercisesTransactions from 'components/pages/ExercisesTransactions';
import GuestLogin from 'components/pages/GuestLogin';
import ProfilePage from 'components/pages/Profile';
import CassetteInfoPage from 'components/pages/CassetteInfo';
import ErrorPage from 'components/pages/ErrorPage';
import ResetPassword from 'components/pages/ResetPassword';
import SetPassword from 'components/pages/SetPassword';
import LoginContainer from 'components/pages/GoogleLogin';
import PrivateRoute from './PrivateRoute';
import SetPasswordSuccesfully from 'components/pages/SetPasswordSuccesfully';

const Routes: React.FunctionComponent = () => (
  <Switch>
    <PrivateRoute path={ROUTES.HOME} component={HomePage} exact />
    <PrivateRoute
      path={ROUTES.POINTS_HISTORY}
      component={PointsTransactions}
      exact
    />
    <PrivateRoute
      path={ROUTES.EXERCISES_HISTORY}
      component={ExercisesTransactions}
      exact
    />
    <PrivateRoute path={ROUTES.PROFILE} component={ProfilePage} exact />
    <PrivateRoute
      path={ROUTES.CASSETTE_INFO}
      component={CassetteInfoPage}
      exact
    />
    <Route path={ROUTES.LOGIN} component={LoginContainer} exact />
    <Route path={ROUTES.GUEST_LOGIN} component={GuestLogin} exact />
    <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} exact />
    <Route path={ROUTES.SET_PASSWORD} component={SetPassword} exact />
    <Route
      path={ROUTES.PASSWORD_RESET_SUCCESSFULLY}
      component={SetPasswordSuccesfully}
      exact
    />
    <Route component={ErrorPage} />
  </Switch>
);

export default Routes;
