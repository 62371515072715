import { useAppContext } from 'containers/App/AppContext';
import { useState, useEffect } from 'react';

import Axios from 'utils/axios';
import { ENDPOINTS } from 'utils/constants';
import { errorHandler } from 'utils/helper';

const useCheckToken = (token?: string) => {
  const { setGlobalError } = useAppContext();
  const [loading, setLoading] = useState(true);

  const [fetchingError, setFetchingError] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      if (token === '') return;
      setLoading(true);

      try {
        await Axios.get(
          `${ENDPOINTS.CHECK_TOKEN}?reset_password_token=${token}`,
        );

        setLoading(false);
      } catch (error) {
        errorHandler(error, setGlobalError);

        setFetchingError(error.toString());
        setLoading(false);
      }
    };

    fetchData();
  }, [token, setGlobalError]);

  return { loading, fetchingError };
};

export default useCheckToken;
