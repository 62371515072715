import React from 'react';
import { Header, Image, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

const ResetPasswordLink: React.FunctionComponent = () => {
  return (
    <Segment
      color="purple"
      data-testid="ResetPasswordLink"
      className="ResetPasswordLink"
      inverted
      as={Link}
      to={ROUTES.RESET_PASSWORD}
    >
      <Header
        as="h5"
        data-testid="ResetPasswordLink__label"
        className="ResetPasswordLink__label"
        color="pink"
      >
        パスワードを設定する Set password
      </Header>
      <Image
        src={`${process.env.PUBLIC_URL}/icons/arrow@3x.png`}
        data-testid="ResetPasswordLink__icon"
        className="ResetPasswordLink__icon"
        inline
        height="16"
      ></Image>
    </Segment>
  );
};

export default ResetPasswordLink;
