import { useState, useEffect } from 'react';

import { useAppContext } from 'containers/App/AppContext';
import Axios from 'utils/axios';
import { ENDPOINTS, POINTS_HISTORY_DATA } from 'utils/constants';

import { errorHandler } from 'utils/helper';
import { PointsHistoryState } from 'types';

const useGetHistory = () => {
  const { setGlobalError } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [pointsHistoryData, setPointsHistoryData] = useState<
    PointsHistoryState
  >(POINTS_HISTORY_DATA);
  const [fetchingError, setFetchingError] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await Axios.get(ENDPOINTS.HISTORY, {
          withCredentials: true,
        });

        setPointsHistoryData(response.data);
        setLoading(false);
      } catch (error) {
        errorHandler(error, setGlobalError);

        setFetchingError(error.toString());
        setLoading(false);
      }
    };

    fetchData();
  }, [setGlobalError]);

  return { loading, pointsHistoryData, fetchingError };
};

export default useGetHistory;
