import React, { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import 'index.css';

const Footer: React.FunctionComponent = () => {
  const [language, setLanguage] = useState('Japanese');
  const handleChangeLanguage = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    language: string,
  ) => {
    e.preventDefault();
    setLanguage(language);
  };
  return (
    <Segment data-testid="Footer" className="Footer" inverted color="red">
      <div className="Footer__GuideLanguage">
        <span
          onClick={(e) => handleChangeLanguage(e, 'Japanese')}
          className={`Footer__GuideLanguage__choice
            ${language !== 'Japanese' && 'Footer__GuideLanguage__otherchoice'}
          }}`}
        >
          日本語
        </span>
        |
        <span
          onClick={(e) => handleChangeLanguage(e, 'Vietnamese')}
          className={`Footer__GuideLanguage__choice
            ${language !== 'Vietnamese' && 'Footer__GuideLanguage__otherchoice'}
          }}`}
        >
          Việt Nam
        </span>
        |
        <span
          onClick={(e) => handleChangeLanguage(e, 'English')}
          className={`Footer__GuideLanguage__choice
            ${language !== 'English' && 'Footer__GuideLanguage__otherchoice'}
          }}`}
        >
          English
        </span>
      </div>
      {language === 'Japanese' && (
        <div className="Footer__Content__jp">
          <div className="Info__Content">
            <p className="Footer__header">今後FAQの発表を予定しています。</p>
            <p className="Footer__header">それまではSo-fun事務局</p>
            <p className="Footer__header">
              （so-fun@sun-asterisk.com）までご相談ください。{' '}
            </p>
          </div>
          <p className="Footer__header">＜ご留意事項＞</p>
          <p>
            1. 本書は、株式会社Sun Asteriskの取締役である
            平井誠人が委託者となって設定された時価発行新株予約権信託®を活用したインセンティブ・プランにおける貴殿への新株予約権割当ポイントの配分の状況を説明する文書であり、当社の発行する有価証券に関する売付けの申込み又は買付けの申込みの勧誘等を行うものではありません。実際の第1回新株予約権の交付に際しては、交付基準時に先立ち、必要に応じて会社法、金融商品取引法その他の手続きが必要になる場合がありますのでご留意ください。
          </p>
          <p>
            2.
            本書に述べる新株予約権割当ポイントとは、当社が交付基準時※3において第1回新株予約権を取得する受益者を確定するための参考数値であり、本書は貴殿が受益者となること又は貴殿が受益者となった場合の信託受益権の対象となる第1回新株予約権の個数を保証するものではなく、貴殿が本書を基に当社に対して何らかの権利を持つものではありません。受益者及び第1回新株予約権の個数の確定は、当社が、当該信託の交付基準時において貴殿が以下の前提条件を充足していることを確認した上で、貴殿を受益者として特定個数の第1回新株予約権を受領すべき信託受益権の割合を指定することで初めて（他の受益者と同時に）行われるものであり、貴殿は交付基準時に上記受益者として指定されることで第1回新株予約権の交付を受けられる権利を有することになります。
          </p>
          <p>
            (1)
            当該信託に定める交付基準時において第1回新株予約権の内容である行使条件※4を充足していること。
          </p>
          <p>
            (2)
            交付基準時まで継続して役職員として在籍し、当該インセンティブ付与の事実やポイント数等に関して守秘義務を負うこと。
          </p>
          <p>
            (3)
            その他当社が定める交付ガイドライン※5に定める条件を充足していること。
          </p>
          <br />
          <p className="Footer__header">＜用語解説＞</p>
          <p>
            ※1…「第1回新株予約権」とは、1個当たり1,600円（以下「行使価額」といいます。）を払い込むことにより当社の普通株式20株を取得できる会社法上認められた権利です。但し、第1回新株予約権には以下の行使条件が設定されており、交付基準時に先立ち、これが達成されない場合には失効する可能性があります。
          </p>
          <p>
            ※2…「時価発行新株予約権信託®」とは、委託者が拠出した資金で発行された新株予約権を受託者の下で管理させ、交付基準時に確定した受益者に新株予約権を引き渡すことを内容とする信託です。
          </p>
          <p>
            ※3…「交付基準時」とは、第1回新株予約権の交付が行われる日時を言います。具体的には、信託①は当社の株式が金融商品取引所に上場した日から半年が経過した日、信託②は3年が経過した日、信託③は5年が経過した日（営業日でないときは翌営業日とします。）と設定されております。
          </p>
          <p>
            ※4…
            「行使条件」とは、第1回新株予約権を権利行使するための条件です。具体的には以下のとおりです。
          </p>

          <p>
            ①
            受益者は、2019年12月期から2021年12月期までのいずれかの期の当社損益計算書（連結損益計算書を作成した場合には、連結損益計算書とします。）における営業利益が、600百万円を超過した場合にのみ第1回新株予約権を行使することができます。なお、国際財務報告基準の適用、決算期の変更等により参照すべき項目の概念に重要な変更があった場合には、別途参照すべき指標を当社取締役会にて定めるものとします。
          </p>

          <p>
            ②
            上記①にかかわらず、受益者は、第1回新株予約権の割当日から行使期間の満了日までにおいて次に掲げる各事由が生じた場合には、残存するすべての第1回新株予約権を行使することができないものとします。
          </p>
          <p>
            (a)
            1株当たり80円を下回る価格を対価とする当社普通株式の発行等が行われた場合（払込金額が会社法第199条第３項・同第200条第２項に定める「特に有利な金額である場合」を除きます。）。
          </p>
          <p>
            (b)
            第1回新株予約権の目的である当社普通株式が日本国内のいずれの金融商品取引所にも上場されていない場合、1株当たり80円を下回る価格を対価とする売買その他の取引が行われたとき（当該取引時点における株式価値よりも著しく低いと認められる価格で取引が行われた場合を除きます。）。
          </p>
          <p>
            (c)
            第1回新株予約権の目的である当社普通株式が日本国内のいずれかの金融商品取引所に上場された場合以降、当該金融商品取引所における当社普通株式の普通取引の終値が、1株当たり80円を下回る価格となったとき。
          </p>
          <p>
            (d)
            第1回新株予約権の目的である当社普通株式が日本国内のいずれの金融商品取引所にも上場されていない場合、DCF法ならびに類似会社比較法等の方法により評価された株式評価額が1株当たり80円を下回ったとき。{' '}
          </p>
          <p>
            ③
            受益者は、第1回新株予約権を行使する時まで継続して、当社または当社の子会社・関連会社の取締役、監査役及び従業員並びに顧問、業務委託先及び業務提携先であることを要する。ただし、任期満了による退任、定年退職、その他正当な理由があると当社取締役会が認めた場合は、この限りではない。{' '}
          </p>
          <p>④ 受益者の相続人による第1回新株予約権の行使は認めない。</p>
          <p>
            ※5…「交付ガイドライン」とは、交付基準時に第1回新株予約権を交付される受益者と受益者が受領する第1回新株予約権の数を確定させるためのガイドラインです。
          </p>
        </div>
      )}
      {language === 'Vietnamese' && (
        <div className="Footer__Content__vn">
          <div className="Info__Content">
            <p className="Footer__header">
              Các câu hỏi thường gặp sẽ được công bố trong thời gian tới.
            </p>
            <p className="Footer__header">
              Cho đến lúc đó, nếu có bất kỳ câu hỏi nào,
            </p>
            <p className="Footer__header">
              xin vui lòng liên hệ với Ban thư ký So-fun
              (so-fun@sun-asterisk.com).{' '}
            </p>
          </div>
          <p className="Footer__header">＜Lưu ý quan trọng＞</p>
          <p>
            1. Tài liệu này đề cập tới việc phân phối điểm phân bổ quyền đặt mua
            cổ phiếu dành cho Ông/Bà nằm trong Kế hoạch ưu đãi về việc ủy thác
            quyền đặt mua cổ phiếu phát hành theo giá hiện hành ® được quy định
            và công bố bởi công ty cổ phần Sun Asterisk, do ông Makoto Hirai -
            thành viên Ban Giám đốc là người ủy quyền. Tài liệu này không được
            coi là một bản chào bán hay thu hút mua cổ phiếu do công ty phát
            hành. Xin lưu ý, trên thực tế có thể phát sinh một số thủ tục cần
            thiết khác theo Luật doanh nghiệp, Luật giao dịch chứng khoán trước
            khi thực hiện việc cấp quyền mua cổ phiếu phát hành mới.
          </p>
          <p>
            2. Điểm phân bổ quyền mua cổ phiếu phát hành mới được nói tới trong
            tài liệu này có giá trị tham khảo để xác định người thụ hưởng được
            cấp quyền ưu tiên đặt mua cổ phiếu tại thời điểm công ty thực hiện
            việc cấp quyền đó※3. Tài liệu này không đảm bảo Ông/Bà là người thụ
            hưởng quyền hoặc số lượng cổ phiếu được ưu tiên đặt mua trong trường
            hợp Ông/Bà là người được ủy thác quyền mua cổ phiếu phát hành mới.
            Xin nhấn mạnh, tài liệu này không đảm bảo bất kỳ quyền lợi nào của
            Ông/Bà. Người thụ hưởng quyền và số lượng cổ phiếu được ưu tiên đặt
            mua được xác định như sau: Sau khi công ty xác nhận rằng, tại thời
            điểm công ty thực hiện việc cấp quyền ủy thác tương ứng Ông/Bà đáp
            ứng đầy đủ các điều kiện tiên quyết dưới đây, với tư cách là người
            thụ hưởng quyền, Ông/Bà sẽ được chỉ định tỷ lệ quyền mua cổ phiếu
            phát hành mới với số lượng cụ thể (cùng với những người thụ hưởng
            quyền khác). Khi đó Ông/Bà sẽ là người thụ hưởng quyền tại thời điểm
            công ty thực hiện việc cấp quyền, và được hưởng đầy đủ quyền mua cổ
            phiếu phát hành mới.
          </p>
          <p>
            (1) Đáp ứng đầy đủ các Điều kiện thực hiện※4 về quyền mua cổ phiếu
            phát hành mới tại thời điểm cấp quyền được quy định trong nội dung
            ủy thác tương ứng.
          </p>
          <p>
            (2) Là nhân viên chính thức liên tục của công ty đến thời điểm cấp
            quyền, có nghĩa vụ bảo mật các thông tin liên quan đến chính sách ưu
            đãi và số điểm phân bổ.
          </p>
          <p>
            (3) Đáp ứng đầy đủ các điều kiện khác được nêu tại Hướng dẫn cấp
            quyền ※5 do công ty quy định.
          </p>
          <br />
          <p className="Footer__header">＜Giải thích thuật ngữ＞</p>
          <p>
            ※1…“Quyền mua cổ phiếu phát hành mới” là quyền lợi hợp pháp theo
            Luật doanh nghiệp, cho phép người thụ hưởng quyền có thể mua 20 cổ
            phiếu phổ thông của Công ty với mức giá là 1.600 Yên Nhật/1 quyền
            mua cổ phiếu (dưới đây gọi là “Giá thực hiện (Exercise price)”). Tuy
            nhiên, quyền này quy định một số điều kiện thực hiện dưới đây. Nếu
            không đáp ứng được các điều kiện này thì sẽ không được hưởng quyền.
          </p>
          <p>
            ※2… “Ủy thác quyền đặt mua cổ phiếu phát hành theo giá hiện hành®”
            là sự ủy thác mà theo đó người nhận ủy quyền được phép quản lý quyền
            mua cổ phiếu được phát hành bằng khoản tiền mà người uỷ quyền đã
            thanh toán, thực hiện việc trao quyền mua cổ phiếu cho người thụ
            hưởng quyền được xác định tại thời điểm cấp quyền.
          </p>
          <p>
            ※3… “Thời điểm cấp quyền” là thời điểm tiến hành cấp quyền mua cổ
            phiếu phát hành mới. Cụ thể, Ủy thác ① là thời điểm đủ 06 tháng kể
            từ ngày cổ phiếu của Công ty được niêm yết trên sàn giao dịch chứng
            khoán, Ủy thác ② là thời điểm đủ 03 năm kể từ ngày cổ phiếu của Công
            ty được niêm yết trên sàn giao dịch chứng khoán, Ủy thác ③ là thời
            điểm đủ 05 năm kể từ ngày cổ phiếu của Công ty được niêm yết trên
            sàn giao dịch chứng khoán ((nếu trùng với ngày nghỉ thì là ngày làm
            việc kế tiếp) .
          </p>
          <p>
            ※4… “Điều kiện thực hiện” là các điều kiện cần có để thực hiện được
            quyền mua cổ phiếu phát hành mới, cụ thể như sau:
          </p>

          <p>
            ① Người thụ hưởng quyền chỉ thực hiện được quyền mua cổ phiếu phát
            hành mới khi lợi nhuận kinh doanh trên báo cáo tài chính (hoặc báo
            cáo tài chính hợp nhất) trong bất kỳ giai đoạn nào từ 12/2019 đến
            12/2021 đạt mốc trên 600 triệu Yên Nhật. Ngoài ra, nếu có những thay
            đổi trọng yếu đối với các khái niệm chỉ tiêu tham chiếu do việc áp
            dụng chuẩn mực báo cáo tài chính quốc tế hoặc do thay đổi kỳ quyết
            toán thì Ban Giám đốc công ty sẽ quy định những những chỉ tiêu tham
            chiếu riêng.
          </p>

          <p>
            ② Bất kể việc đáp ứng đủ điều kiện ① ở trên, trong quá trình thực
            hiện, từ ngày chính thức nhận quyền mua cổ phiếu phát hành mới đến
            ngày kết thúc thời gian thực hiện, nếu phát sinh bất kỳ sự kiện nào
            dưới đây thì người thụ hưởng quyền sẽ không thể thực hiện các quyền
            còn lại.
          </p>
          <p>
            (a) Trường hợp cổ phiếu phổ thông của công ty được chào bán với mức
            giá dưới 80 Yên Nhật/1 cổ phiếu (ngoại trừ trường hợp khoản tiền
            thanh toán là “Khoản tiền lợi ích đặc biệt” được quy định tại khoản
            3 điều 199 và khoản 2 điều 200 Luật doanh nghiệp).
          </p>
          <p>
            (b) Trường hợp cổ phiếu phổ thông của công ty (loại cổ phiếu được
            cấp quyền mua) chưa được niêm yết trên bất kỳ sàn giao dịch chứng
            khoán nào của Nhật Bản và được chào bán hoặc thực hiện các giao dịch
            mua bán khác với mức giá dưới 80 Yên Nhật/1 cổ phiếu.
          </p>
          <p>
            (c) Trường hợp sau khi cổ phiếu phổ thông của công ty (loại cổ phiếu
            được cấp quyền mua) được niêm yết trên một sàn giao dịch chứng khoán
            bất kỳ của Nhật Bản, giá đóng cửa giao dịch thông thường của loại cổ
            phiếu đó trên sàn giao dịch chứng khoán tương ứng giảm xuống dưới 80
            Yên Nhật/1 cổ phiếu.
          </p>
          <p>
            (d) Trường hợp cổ phiếu phổ thông của công ty (loại cổ phiếu được
            cấp quyền đặt mua) chưa được niêm yết trên bất kỳ sàn giao dịch
            chứng khoán nào của Nhật Bản, giá trị cổ phiếu của công ty được định
            giá dưới 80 Yên Nhật/1 cổ phiếu bằng phương pháp định giá chiết khấu
            dòng tiền DCF, phương pháp so sánh doanh nghiệp cùng lĩnh vực.
          </p>
          <p>
            ③ Người thụ hưởng quyền phải là Giám đốc, kiểm soát viên, nhân viên,
            nhà thầu phụ, đối tác kinh doanh liên tục của công ty, công ty con
            hoặc công ty liên quan cho đến thời điểm thực hiện quyền mua cổ
            phiếu phát hành mới Tuy nhiên, điều kiện này không áp dụng với những
            trường hợp mãn nhiệm do hết nhiệm kỳ, nghỉ hưu, kết thúc hợp đồng
            hoặc các lý do chính đáng khác được Ban Giám đốc phê duyệt.{' '}
          </p>
          <p>
            ④ Người thừa kế của người thụ hưởng không được phép thực hiện quyền
            mua cổ phiếu phát hành mới.
          </p>
          <p>
            ※5… “Hướng dẫn cấp quyền” là hướng dẫn để xác định người được nhận
            quyền mua cổ phiếu phát hành mới và số lượng cổ phiếu được quyền mua
            tại thời điểm cấp quyền. Trân trọng.
          </p>
          <p>
            Bản dịch tiếng Việt chỉ sử dụng cho mục đích thông tin, và không
            dùng thay thế cho bản tiếng Nhật chính thức. Trong trường hợp có sự
            mâu thuẫn về nội dung giữa bản tiếng Nhật và tiếng Việt, bản tiếng
            Nhật sẽ được ưu tiên áp dụng.
          </p>
        </div>
      )}
      {language === 'English' && (
        <div className="Footer__Content__en">
          <div className="Info__Content">
            <p className="Footer__header">The FAQ will be published soon.</p>
            <p className="Footer__header">
              Until then, if you have any questions,
            </p>
            <p className="Footer__header">
              please contact the So-fun Secretariat (so-fun@sun-asterisk.com).{' '}
            </p>
          </div>
          <p className="Footer__header">＜Important Notes＞</p>
          <p>
            1. This document describes the allocation of allocation points of
            Share Options to you in the incentive plan using the market
            value-issued share options trust®, which was set up by Mr. Makoto
            Hirai, a Director of Sun Asterisk Inc. This is not a document for
            making an offer to sell or soliciting an offer to purchase
            securities issued by the Company. Please note that other procedures
            may be required as necessary in accordance with the Companies Law,
            the Financial Instruments and Exchange Law prior to the issuance of
            the First Share Options.
          </p>
          <p>
            2. The allocation points of Share Options described in this document
            are reference values for the Company to determine the beneficiaries
            who will acquire the First Share Options at the Issuance Time
            (referred to ※3 below). This document does not guarantee that you
            will be a beneficiary or the number of First Share Options that are
            subject to trust beneficiary rights in the event that you become a
            beneficiary. You do not have any rights to us based on this
            document. The number of beneficiaries and the number of the First
            Share Options will be determined by the Company, after confirming
            that you satisfy the following prerequisites at the time of the
            issuance of the trust, and this will implemented for the first time
            (at the same time as other beneficiaries) by specifying the
            percentage of trust beneficiary rights for which you will receive a
            specified number of the First Share Options as the beneficiary. You
            will have the right to receive the First Share Options by being
            designated as a beneficiary at the time of issuance.
          </p>
          <p>
            (1) Exercise condition ※4, which is the content of the First Share
            Options, must be satisfied at the Issuance Time specified in the
            trust.
          </p>
          <p>
            (2) To be an employee until the Issuance Time, and have a duty of
            confidentiality regarding the giving of the incentive and the number
            of allocation points.
          </p>
          <p>
            (3) Other conditions specified in the Guidelines for Issuance
            (referred to ※5 below) set by the Company must be satisfied.
          </p>
          <br />
          <p className="Footer__header">＜Descriptions of terms＞</p>
          <p>
            ※1… “First Share Options” is a right stipulated by the Companies Act
            that allows twenty shares of the Company&apos;s common shares to be
            acquired by paying ¥ 1,600 per point (hereinafter referred to as
            “Exercise Price”). However, the following exercise conditions are
            set for the First Share Options, and if it is not achieved prior to
            the Issuance Time, it may expire.
          </p>
          <p>
            ※2… “Market Value-issued Share Options Trust®” means a trust that
            the Share Options issued with the funds contributed by the Trustor
            are managed by the Trustee, and the Share Options are delivered to
            the beneficiary determined at the Issuance Time.
          </p>
          <p>
            ※3… “Issuance Time” means the date and time when the First Share
            Options are issued. Specifically, Trust ① is the date after 06
            months from the date the Company&apos;s shares were listed on the
            financial instruments exchange, Trust ② is the date after 03 years
            from the date the Company&apos;s shares were listed on the financial
            instruments exchange, Trust ③ is the date after 05 years from the
            date the Company&apos;s shares were listed on the financial
            instruments exchange (the next business day if it is not a business
            day).
          </p>
          <p>
            ※4… “Exercise Conditions” are conditions for exercising the First
            Share Options. Specifically:
          </p>

          <p>
            ① The beneficiary can exercise the First Share Options only when the
            operating income in the Company&apos;s Statement of Income (or
            consolidated statement of income, if a consolidated statement of
            income has been prepared) for any period from the fiscal year ended
            December 2019 to the fiscal year ended December 2021 exceeds ¥ 600
            million. In addition, if there is a significant change in the
            definition of reference index due to the application of
            International Financial Reporting Standards, changes in the fiscal
            year, etc., the Board of Directors will specify the separate
            reference index.
          </p>

          <p>
            ② Regardless of ① above, if any of the following events occur
            between the date of allotment of the First Share Options and the
            expiration date of the exercise period, the beneficiary shall not be
            able to exercise any remaining First Share Options.
          </p>
          <p>
            (a) In the event that the Company&apos;s common shares are issued at
            a price of less than ¥ 80 per share (except where the payment amount
            is a particularly favorable amount specified in Article 199,
            Paragraph 3 and Article 200, Paragraph 2 of the Companies Act.)
          </p>
          <p>
            (b) If the Company&apos;s common shares, which are the object of the
            First Share Options, are not listed on any financial instruments
            exchange in Japan, and are offered for sale or other transaction
            with a price of less than ¥ 80 per share (except when transactions
            are made at a price that is deemed to be significantly lower than
            the share value at the time of the transaction).
          </p>
          <p>
            (c) After the Company&apos;s common shares, which is the object of
            the First Share Options, are listed on one of the financial
            instruments exchanges in Japan, the closing price of regular
            transaction of the Company&apos;s common shares on the financial
            instruments exchange fall below ¥ 80 per share.
          </p>
          <p>
            (d) If the Company&apos;s common shares, which is the object of the
            First Share Options, are not listed on any financial instruments
            exchange in Japan, the share valuation evaluated by a method such as
            the Discounted Cash Flow (DCF) method or Comparable Company Analysis
            (CCA) method fall below ¥ 80 per share.
          </p>
          <p>
            ③ The beneficiaries must be Directors, Corporate Auditors and
            employees, and advisors, subcontractors, and business partners of
            the Company or its subsidiaries and affiliates until the time of
            exercising the First Share Options. Provided, however, that this
            condition shall not apply to cases of resignation due to the end of
            term, retirement, termination of contract or other legitimate
            reasons approved by the Board of Directors.
          </p>
          <p>
            ④ Exercise of the First Share Options by the beneficiary&apos;s heir
            is not permitted.
          </p>
          <p>
            ※5… “Guidelines for Issuance” are guidelines for determining the
            number of beneficiaries who will be issued the First Share Options
            and the number of First Share Options received by the beneficiaries
            at the Issuance Time. Best regards.
          </p>
          <p>
            The English translation is for infomational purposes only, and is
            not a substitute for the Japanese official version. In case of any
            discrepancy between the Japanese and English version, the Japanese
            vesion shall prevail.
          </p>
        </div>
      )}
    </Segment>
  );
};

export default Footer;
