import React from 'react';

import { useAppContext } from 'containers/App/AppContext';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

type PrivateRouteProps = {
  path: RouteProps['path'];
  exact?: RouteProps['exact'];
  component: React.ElementType;
};
const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  ...routeProps
}) => {
  const { userData } = useAppContext();

  return (
    <>
      <Route
        {...routeProps}
        render={() =>
          userData.name !== '' ? <Component /> : <Redirect to={ROUTES.LOGIN} />
        }
      />
    </>
  );
};

export default PrivateRoute;
