import React, { useEffect, useState } from 'react';
import 'semantic-ui-less/semantic.less';
import { Sidebar } from 'semantic-ui-react';
import './App.css';
import 'index.css';
import { useHistory, useLocation } from 'react-router-dom';
import NavigationMenu from 'components/molecules/Navigation';
import NavigationSidebar from 'components/molecules/Sidebar';
import {
  isGuestRoute,
  isKnownRoute,
  isPrivateRoute,
  isServerOrAuthorizationError,
} from 'utils/helper';
import Routes from './routes';
import { useAppContext } from 'containers/App/AppContext';
import useGetHome from './useGetHome';
import InlineLoader from 'components/atoms/InlineLoader';
import InfoMessage from 'components/atoms/InfoMessage';
import { ERROR_CODES, ROUTES } from 'utils/constants';
import ErrorPage from 'components/pages/ErrorPage';
import useLogout from './useLogout';

const App: React.FC = () => {
  const {
    userData,
    globalError,
    setGlobalError,
    globalLoading,
    setGlobalLoading,
  } = useAppContext();
  useGetHome();
  const location = useLocation();
  const history = useHistory();
  const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);
  const { setLogoutClicked } = useLogout();

  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
  };

  useEffect(() => {
    if (userData.name === '') {
      setSidebarOpened(false);
    }
  }, [userData]);

  useEffect(() => {
    if (
      userData.name !== '' &&
      (location.pathname === ROUTES.LOGIN ||
        location.pathname === ROUTES.GUEST_LOGIN)
    ) {
      history.push(ROUTES.HOME);

      setGlobalLoading(false);
    }
  }, [userData, history, location, setGlobalLoading]);

  useEffect(() => {
    if (
      globalError.errorMessage &&
      !isServerOrAuthorizationError(globalError)
    ) {
      setTimeout(() => {
        setGlobalError({});
      }, 4000);
    }
  }, [globalError, setGlobalError]);

  useEffect(() => {
    if (
      isPrivateRoute(location.pathname) &&
      (globalError.statusCode === ERROR_CODES.FORBIDDEN ||
        globalError.statusCode === ERROR_CODES.UNAUTHORIZED)
    ) {
      history.push(ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalError]);

  return (
    <div className="App">
      {globalError.errorMessage && !isServerOrAuthorizationError(globalError) && (
        <>
          <InfoMessage
            severity="error"
            message={globalError.errorMessage as string}
          />
        </>
      )}
      {globalLoading ? (
        <InlineLoader />
      ) : (
        <>
          {globalError.statusCode === ERROR_CODES.SERVER_ERROR ||
          globalError.statusCode === ERROR_CODES.SERVER_UNAVAILABLE ? (
            <ErrorPage
              errorCode={globalError.statusCode}
              errorMessage={globalError.errorMessage as string}
            />
          ) : (
            <Sidebar.Pushable
              className="pushable"
              style={{ overflowY: sidebarOpened ? 'hidden' : null }}
            >
              <NavigationSidebar
                visible={sidebarOpened}
                setLogoutClicked={setLogoutClicked}
                hideSidebar={() => setSidebarOpened(false)}
              />
              <Sidebar.Pusher
                dimmed={sidebarOpened}
                className={
                  sidebarOpened
                    ? 'Sidebar__Pusher Sidebar__dimmed'
                    : 'Sidebar__Pusher'
                }
              >
                {!isGuestRoute(location.pathname) &&
                  !isKnownRoute(location.pathname) &&
                  userData.name !== '' && (
                    <NavigationMenu toggleSidebar={toggleSidebar} />
                  )}
                <Routes />
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          )}
        </>
      )}
    </div>
  );
};

export default App;
