import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import 'index.css';
type PointsCassetteProps = {
  cassette: { name: string; points: number; share_option: boolean };
};
const PointsCassette: React.FunctionComponent<PointsCassetteProps> = ({
  cassette,
}) => {
  return (
    <Segment
      data-testid="PointsCassette"
      className="PointsCassette"
      color="purple"
      inverted
    >
      {cassette && (
        <div className="PointsCassette__cassette">
          <Header
            as="h4"
            data-testid="PointsCassette__cassetteHeader"
            color="pink"
            className="PointsCassette__cassetteHeader"
          >
            <span className="PointsCassette__cassetteName">
              【{cassette.name}】
            </span>
            <br />
          </Header>
          <p className="PointsCassette__cassetteTexts">
            {cassette.share_option ? '新株予約権' : '新株予約権割当ポイント'}
            <br />
            <span>
              {cassette.share_option
                ? 'Quyền mua lại cổ phiếu'
                : 'Điểm phân bổ quyền mua cổ phiếu'}
            </span>
            <br />
            <span>
              {cassette.share_option
                ? 'Stock acquisition right'
                : 'Stock acquisition rights allocation points'}
            </span>
          </p>
          <Header
            as="h1"
            data-testid="PointsCassette__numPoints"
            color="pink"
            className="PointsCassette__numPoints"
          >
            <span className="PointsCassette__cassettePoint">
              {cassette.points}
            </span>
            <span className="PointsCassette__unitType">
              {cassette.share_option ? '個' : 'pt'}
            </span>
            <br />
          </Header>
        </div>
      )}
    </Segment>
  );
};

export default PointsCassette;
