import React from 'react';
import { Image, Segment, Container, Header, Grid } from 'semantic-ui-react';
import { BIG_MARGIN, COLORS, VALUE } from 'utils/constants';
import 'index.css';
import { ExerciseHistories } from 'types';
import { formatDateWithEn } from 'utils/helper';

type ExercisesTransactionProps = {
  index: number;
};

const ExercisesTransaction: React.FC<
  Omit<ExerciseHistories, 'id'> & ExercisesTransactionProps
> = ({ exercise_date, exercise_amount, cassette_name, index }) => {
  return (
    <Segment
      data-testid="ExercisesTransaction"
      className="ExercisesTransaction"
      style={{
        backgroundColor: COLORS.SLATE_BLUE,
        borderTopLeftRadius: index === 0 ? BIG_MARGIN : 0,
        borderTopRightRadius: index === 0 ? BIG_MARGIN : 0,
      }}
    >
      <Container className="ExercisesTransaction__points__container">
        <Grid>
          <Grid.Row>
            <Grid.Column
              mobile={7}
              tablet={6}
              computer={5}
              largeScreen={5}
              widescreen={5}
            >
              <Header
                data-testid="Exercise_history_point"
                className="Exercise_history_point"
                inverted
              >
                {exercise_amount}
                <span className="Exercise_history_pt">個</span>
              </Header>
              <span
                data-testid="ExercisesTransaction__date"
                className="ExercisesTransaction__date"
              >
                <Image
                  src={`${process.env.PUBLIC_URL}/icons/Calendar@3x.png`}
                  data-testid="ExercisesTransaction__icon"
                  className="ExercisesTransaction__icon"
                  height="15"
                  inline
                  width="15"
                ></Image>
                {formatDateWithEn(exercise_date)}
              </span>
            </Grid.Column>
            <Grid.Column
              mobile={2}
              tablet={4}
              computer={6}
              largeScreen={6}
              widescreen={6}
              centered
            >
              <div className="Exercise_history_arrow">→</div>
            </Grid.Column>
            <Grid.Column
              mobile={7}
              tablet={6}
              computer={5}
              largeScreen={5}
              widescreen={5}
            >
              <Header
                data-testid="ExerciseTransaction__stock"
                className="ExerciseTransaction__stock"
                inverted
              >
                {exercise_amount * VALUE.STOCK_UNIT} <span>株</span>
              </Header>
              <div
                data-testid="exercisesCassette"
                className="exercisesCassette"
              >
                <strong>[{cassette_name}]</strong>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default ExercisesTransaction;
