import React from 'react';
import 'index.css';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

const ReturnLink: React.FunctionComponent = () => {
  return (
    <Link
      data-testid="ReturnLink"
      className="ReturnLink mgTopSmall"
      to={ROUTES.LOGIN}
    >
      back to TOP
    </Link>
  );
};

export default ReturnLink;
