import React from 'react';
import 'index.css';
import { Container, Header, Grid } from 'semantic-ui-react';
import ProfileCard from 'components/molecules/ProfileCard';
import useGetProfile from './useGetProfile';

const ProfilePage: React.FunctionComponent = () => {
  const { loading, profileData } = useGetProfile();

  return (
    <Container data-testid="ProfilePage" className="ProfilePage">
      <Grid className="ProfilePage__Grid">
        <Grid.Row centered>
          <Grid.Column
            mobile={14}
            tablet={8}
            computer={8}
            largeScreen={6}
            widescreen={4}
          >
            <Header
              as="h3"
              data-testid="PointsTransactions__header"
              className="PointsTransactions__header"
            >
              プロフィール
            </Header>
            <span data-testid="SubTitle" className="SubTitle">
              <strong>Hồ sơ | Profile</strong>
            </span>
            <ProfileCard loading={loading} profileData={profileData} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default ProfilePage;
