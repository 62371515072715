import React, { createContext, useState } from 'react';
import { AppState, AppContextProps } from 'types';
import { DEFAULT_APP_STATE, USER_DATA } from 'utils/constants';

export const AppContext = createContext<AppState>(DEFAULT_APP_STATE);

const AppContextProvider: React.FunctionComponent<AppContextProps> = ({
  children,
}) => {
  const [userData, setUserData] = useState(USER_DATA);
  const [globalLoading, setGlobalLoading] = useState<boolean>(true);
  const [globalError, setGlobalError] = useState<
    Record<string, string | number>
  >({});

  return (
    <AppContext.Provider
      value={{
        globalError,
        setGlobalError,
        globalLoading,
        setGlobalLoading,
        userData,
        setUserData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => React.useContext(AppContext);

export default AppContextProvider;
