import { useAppContext } from 'containers/App/AppContext';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginCredentialsType } from 'types';

import Axios from 'utils/axios';
import { ENDPOINTS, ROUTES } from 'utils/constants';
import { errorHandler } from 'utils/helper';

const useGuestLogin = (initialLoginCredentials?: LoginCredentialsType) => {
  const history = useHistory();
  const { setGlobalError, setGlobalLoading } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [loginCredentials, setLoginCredentials] = useState<
    LoginCredentialsType
  >(
    initialLoginCredentials
      ? initialLoginCredentials
      : { email: '', password: '' },
  );

  const [fetchingError, setFetchingError] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      if (loginCredentials.email === '' && loginCredentials.password === '')
        return;

      setLoading(true);
      setGlobalLoading(true);

      try {
        await Axios.post(
          ENDPOINTS.GUEST_LOGIN,
          { ...loginCredentials },
          { withCredentials: true },
        );

        setLoading(false);

        history.push(ROUTES.HOME);
      } catch (error) {
        errorHandler(error, setGlobalError);

        setFetchingError(error.toString());

        setGlobalLoading(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [loginCredentials, setGlobalLoading, history, setGlobalError]);

  return { loading, setLoginCredentials, fetchingError };
};

export default useGuestLogin;
