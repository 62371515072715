import React, { useState } from 'react';
import LoginPage from 'components/pages/Login';
import { GoogleOAuthProvider } from '@react-oauth/google';

import useLogin from './useLogin';

const LoginContainer: React.FunctionComponent = () => {
  const [googleToken, setGoogleToken] = useState<string>('');
  const { loading } = useLogin(googleToken);

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
    >
      <LoginPage loading={loading} setGoogleToken={setGoogleToken} />
    </GoogleOAuthProvider>
  );
};

export default LoginContainer;
