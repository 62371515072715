import React from 'react';
import { Card, Form, Button } from 'semantic-ui-react';
import 'index.css';
import { Controller, useForm } from 'react-hook-form';
import { ResetPasswordType } from 'types';

import {
  INVALID_PASSWORD_ERROR_MESSAGE,
  VALID_PASSWORD_CHECK_REGEX,
} from 'utils/constants';

//TODO:
//For now I am using !important to get the margins, but it is better to configure it from Semantic UI itself
// as there are a gutter-width and row-margin to consider, I will take a look at it in another PR for improvement
// the quality of the code.

type SetPasswordFormProps = {
  loading: boolean;
  setPasswords: React.Dispatch<React.SetStateAction<ResetPasswordType>>;
};

const SetPasswordForm: React.FC<SetPasswordFormProps> = ({
  loading,
  setPasswords,
}) => {
  const { handleSubmit, errors, control, watch } = useForm({
    mode: 'onChange',
  });

  const submitForm = handleSubmit((values) => {
    setPasswords(values as ResetPasswordType);
  });

  return (
    <Form
      data-testid="SetPasswordForm"
      className="SetPasswordForm"
      onSubmit={submitForm}
    >
      <Card fluid className="SetPasswordForm__card">
        <Card.Content>
          <Form.Field data-testid="SetPasswordForm__password" required>
            <label className="LoginForm__label">Password</label>

            <Controller
              data-testid="LoginForm__password"
              control={control}
              as={Form.Input}
              className="FormInput"
              type="password"
              name="password"
              defaultValue=""
              rules={{
                required: '必須項目',
                pattern: {
                  value: VALID_PASSWORD_CHECK_REGEX,
                  message: INVALID_PASSWORD_ERROR_MESSAGE,
                },
              }}
              error={errors && errors.password && errors.password.message}
            />
          </Form.Field>
          <Form.Field
            data-testid="SetPasswordForm__password_confirmation"
            required
          >
            <label className="LoginForm__label">Password(comfirm)</label>
            <Controller
              data-testid="LoginForm__password"
              control={control}
              as={Form.Input}
              className="FormInput"
              type="password"
              name="password_confirmation"
              defaultValue=""
              rules={{
                required: '必須項目',
                validate: (value) =>
                  value === watch('password') || 'パスワードが違います。',
              }}
              error={
                errors &&
                errors.password_confirmation &&
                errors.password_confirmation.message
              }
            />
          </Form.Field>
          <p className="PasswordChangeInformation">
            任意のパスワードを入力してください。
          </p>
        </Card.Content>
      </Card>
      <Button
        loading={loading}
        type="submit"
        data-testid="SetPasswordForm__button"
        fluid
        circular
        color="teal"
        size="big"
      >
        Send
      </Button>
    </Form>
  );
};

export default SetPasswordForm;
