import { useAppContext } from 'containers/App/AppContext';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Axios from 'utils/axios';
import { ENDPOINTS, ROUTES, USER_DATA } from 'utils/constants';
import { errorHandler } from 'utils/helper';

const useLogout = (clicked?: boolean) => {
  const history = useHistory();
  const { setGlobalError, setGlobalLoading, setUserData } = useAppContext();
  const [fetchingError, setFetchingError] = useState<string>();
  const [logoutClicked, setLogoutClicked] = useState<boolean>(
    clicked ? clicked : false,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!logoutClicked) return;
      setGlobalLoading(true);

      try {
        await Axios.delete(ENDPOINTS.LOGOUT, { withCredentials: true });
        setGlobalLoading(false);
        setUserData(USER_DATA);
        setLogoutClicked(false);

        history.push(ROUTES.LOGIN);
      } catch (error) {
        errorHandler(error, setGlobalError);

        setFetchingError(error.toString());
        setGlobalLoading(false);
      }
    };

    fetchData();
  }, [setUserData, logoutClicked, history, setGlobalLoading, setGlobalError]);

  return { setLogoutClicked, fetchingError };
};

export default useLogout;
