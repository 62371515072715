import { useAppContext } from 'containers/App/AppContext';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Axios from 'utils/axios';
import { ENDPOINTS } from 'utils/constants';
import { errorHandler } from 'utils/helper';

const useResetPassword = (resetEmail: string) => {
  const history = useHistory();
  const { setGlobalError } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [fetchingError, setFetchingError] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      if (resetEmail === '') return;

      setLoading(true);

      try {
        await Axios.post(ENDPOINTS.PASSWORD_RESET_REQUEST, {
          email: resetEmail,
        });
        setLoading(false);
        setResponseMessage('メールを送りました。');
      } catch (error) {
        errorHandler(error, setGlobalError);
        setFetchingError(error.toString());
        setLoading(false);
      }
    };

    fetchData();
  }, [resetEmail, history, setGlobalError]);

  return { loading, responseMessage, fetchingError };
};

export default useResetPassword;
