import React from 'react';
import { Image, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
type NavigationMenuProps = {
  toggleSidebar: () => void;
};

const NavigationMenu: React.FunctionComponent<NavigationMenuProps> = ({
  toggleSidebar,
}) => {
  return (
    <Menu
      secondary
      data-testid="NavigationMenu"
      inverted
      className="NavigationMenu"
    >
      <Menu.Item
        position="left"
        data-testid="NavigationMenu__hamburger"
        onClick={() => toggleSidebar()}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/icons/Hamburgermenu@3x.png`}
          width="26"
          className="NavigationMenu__hamburger"
        ></Image>
      </Menu.Item>
      <Menu.Item
        className="NavigationMenu__logoContainer"
        as={Link}
        to={ROUTES.HOME}
      >
        <Image
          data-testid="Logo"
          src={`${process.env.PUBLIC_URL}/images/logo@3x.png`}
          inline
        ></Image>
      </Menu.Item>
    </Menu>
  );
};

export default NavigationMenu;
