import React from 'react';
import { Image, Segment, Container, Header } from 'semantic-ui-react';
import { SOURCE_DICTIONARY, BIG_MARGIN, SOURCE_STRING } from 'utils/constants';
import 'index.css';
import { PointHistories } from 'types';
import { formatDateWithEn } from 'utils/helper';

type PointsTransactionProps = {
  index: number;
};

const PointsTransaction: React.FC<
  Omit<PointHistories, 'id'> & PointsTransactionProps
> = ({ grant_date, point_amount, point_type, cassette_name, index }) => {
  return (
    <Segment
      data-testid="PointsTransaction"
      className="PointsTransaction"
      style={{
        backgroundColor: SOURCE_DICTIONARY[point_type],
        borderTopLeftRadius: index === 0 ? BIG_MARGIN : 0,
        borderTopRightRadius: index === 0 ? BIG_MARGIN : 0,
      }}
    >
      <Container>
        <Header
          as="h5"
          data-testid="PointsTransaction__source"
          className="PointsTransaction__source"
          textAlign="left"
          inverted
        >
          {SOURCE_STRING[point_type]}
        </Header>
        <p className="PointsTransaction__wrapper">
          <Image
            src={`${process.env.PUBLIC_URL}/icons/Calendar@3x.png`}
            data-testid="PointsTransaction__icon"
            className="PointsTransaction__icon"
            height="15"
            inline
            width="15"
          ></Image>
          <span
            data-testid="PointsTransaction__date"
            className="PointsTransaction__date"
          >
            {formatDateWithEn(grant_date)}
          </span>
        </p>
      </Container>
      <Container className="PointsTransaction__points__container">
        <Header
          data-testid="PointsTransaction__points"
          className="PointsTransaction__points"
          inverted
        >
          {point_amount} <span>pt</span>
        </Header>
        <span data-testid="PointsCassette" className="PointsCassette">
          <strong>[{cassette_name}]</strong>
        </span>
      </Container>
    </Segment>
  );
};

export default PointsTransaction;
