import React from 'react';
import 'index.css';
type ProfileValueProps = {
  value: string;
};

const ProfileValue: React.FunctionComponent<ProfileValueProps> = ({
  value,
}) => {
  return (
    <p
      data-testid="ProfileContainer__value"
      className="ProfileContainer__value"
    >
      {value}
    </p>
  );
};

export default ProfileValue;
