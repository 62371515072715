import React from 'react';
import { Container, Header, Grid, Image } from 'semantic-ui-react';
import PointsCassette from 'components/molecules/PointsCassette';
import PointsHistoryLink from 'components/molecules/PointsHistoryLink';
import FinanceLink from 'components/molecules/FinanceLink';
import Footer from 'components/molecules/Footer';
import { useAppContext } from 'containers/App/AppContext';
import { getCurrentDateEn } from 'utils/helper';

const HomePage: React.FunctionComponent = () => {
  const { userData } = useAppContext();

  return (
    <Container data-testid="HomePage" id="HomePage" className="HomePage">
      <Grid className="HomePage__Grid FullWidthGrid NoMargins" padded={false}>
        <Grid.Row centered>
          <Grid.Column
            mobile={14}
            tablet={8}
            computer={8}
            largeScreen={6}
            widescreen={4}
          >
            <Header
              as="h4"
              textAlign="left"
              className="HomePage__header"
              data-testid="HomePage__header"
            >
              おかえりなさい、
              <br />
              <span>Chào mừng trở lại | Welcome back</span>
              <br />
              {userData.name}さん
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column
            mobile={14}
            tablet={8}
            computer={8}
            largeScreen={5}
            widescreen={3}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/icons/Sunpoint_red@3x.png`}
              centered
              data-testid="PointsCassette__icon"
              className="mgBottomSmall"
              height="62"
              width="62"
            ></Image>
            <Header
              as="h5"
              data-testid="HomePage__current-date"
              className="HomePage__current-date"
              color="red"
            >
              {getCurrentDateEn()}
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            largeScreen={5}
            widescreen={3}
          >
            {userData.cassette.map((cassette, index) => {
              return (
                <>
                  {cassette && (cassette.exists || index < 3) && (
                    <PointsCassette cassette={cassette}></PointsCassette>
                  )}
                </>
              );
            })}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            largeScreen={5}
            widescreen={3}
          >
            <PointsHistoryLink />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            largeScreen={5}
            widescreen={3}
          >
            <FinanceLink />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered style={{ paddingBottom: 0 }}>
          <Grid.Column
            mobile={16}
            tablet={10}
            computer={9}
            largeScreen={5}
            widescreen={5}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <Footer />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default HomePage;
