import React from 'react';
import { Card, Form, Button } from 'semantic-ui-react';
import 'index.css';

import {
  INVALID_EMAIL_ERROR_MESSAGE,
  VALID_EMAIL_CHECK_REGEX,
} from 'utils/constants';
import { LoginCredentialsType } from 'types';

import { useForm, Controller } from 'react-hook-form';

//TODO:
//For now I am using !important to get the margins, but it is better to configure it from Semantic UI itself
// as there are a gutter-width and row-margin to consider, I will take a look at it in another PR for improvement
// the quality of the code.

type LoginFormType = {
  loading: boolean;
  setLoginCredentials: React.Dispatch<
    React.SetStateAction<LoginCredentialsType>
  >;
};

const LoginForm: React.FC<LoginFormType> = ({
  loading,
  setLoginCredentials,
}) => {
  const { handleSubmit, errors, control } = useForm({
    mode: 'onChange',
  });

  const submitForm = handleSubmit((values) => {
    setLoginCredentials(values as LoginCredentialsType);
  });

  return (
    <Form data-testid="LoginForm" className="LoginForm" onSubmit={submitForm}>
      <Card fluid className="LoginForm__email__card">
        <Card.Content>
          <Form.Field required className="LoginForm__email mgBottomBig">
            <label className="LoginForm__label">E-Mail Address</label>

            <Controller
              data-testid="LoginForm__email"
              control={control}
              as={Form.Input}
              className="FormInput"
              type="email"
              name="email"
              defaultValue=""
              rules={{
                required: '必須項目',
                pattern: {
                  value: VALID_EMAIL_CHECK_REGEX,
                  message: INVALID_EMAIL_ERROR_MESSAGE,
                },
              }}
              error={errors && errors.email && errors.email.message}
            />
          </Form.Field>
          <Form.Field required>
            <label className="LoginForm__label">Password</label>
            <Controller
              data-testid="LoginForm__password"
              control={control}
              as={Form.Input}
              className="FormInput"
              type="password"
              name="password"
              defaultValue=""
              rules={{
                required: '必須項目',
              }}
              error={errors && errors.password && errors.password.message}
            />
            <p className="LoginForm__information">
              指定されたログイン用メールアドレスと
              <br />
              パスワードを入力してください
            </p>
          </Form.Field>
        </Card.Content>
      </Card>
      <Button
        loading={loading}
        type="submit"
        data-testid="LoginForm__button"
        fluid
        circular
        color="teal"
        size="big"
      >
        Sign in
      </Button>
    </Form>
  );
};

export default LoginForm;
