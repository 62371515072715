import { useState, useEffect } from 'react';

import { useAppContext } from 'containers/App/AppContext';
import Axios from 'utils/axios';
import { ENDPOINTS, CASSETTE_INFO_DATA } from 'utils/constants';

import { errorHandler } from 'utils/helper';
import { CassetteInfoState } from 'types';

const useGetCassetteInfo = () => {
  const { setGlobalError } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [CassetteInfoData, setCassetteInfoData] = useState<CassetteInfoState>(
    CASSETTE_INFO_DATA,
  );
  const [fetchingError, setFetchingError] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await Axios.get(ENDPOINTS.CASSETTE_INFO, {
          withCredentials: true,
        });

        setCassetteInfoData(response.data);
        setLoading(false);
      } catch (error) {
        errorHandler(error, setGlobalError);

        setFetchingError(error.toString());
        setLoading(false);
      }
    };

    fetchData();
  }, [setGlobalError]);

  return { loading, CassetteInfoData, fetchingError };
};

export default useGetCassetteInfo;
