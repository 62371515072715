import React from 'react';
import 'index.css';

import { Button } from 'semantic-ui-react';
import { ROUTES } from 'utils/constants';

import { Link } from 'react-router-dom';

const LoginLink: React.FunctionComponent = () => {
  return (
    <Link to={ROUTES.GUEST_LOGIN} className="MailLoginButton">
      <Button
        to={ROUTES.GUEST_LOGIN}
        data-testid="LoginForm__button"
        fluid
        circular
        color="teal"
        size="big"
        className="MailLoginButton__Button"
      >
        メールアドレス認証でサインイン
        <br />
        Sign in with E-Mail Address
      </Button>
    </Link>
  );
};

export default LoginLink;
