import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';
import ReturnLink from 'components/atoms/ReturnLink';
import { ROUTES } from 'utils/constants';
import { Link } from 'react-router-dom';

const SetPasswordSuccesfully: React.FunctionComponent = () => {
  return (
    <Container
      data-testid="SetPasswordSuccessfully"
      className="SetPasswordSuccessfully"
    >
      <Grid padded centered columns={1} verticalAlign="middle">
        <Grid.Row>
          <Grid.Column
            mobile={14}
            tablet={14}
            computer={14}
            largeScreen={14}
            widescreen={14}
          >
            <Header as="h4">パスワードの設定が完了しました</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            mobile={14}
            tablet={14}
            computer={14}
            largeScreen={14}
            widescreen={14}
          >
            <Link to={ROUTES.GUEST_LOGIN} className="MailLoginButton">
              <Button
                to={ROUTES.GUEST_LOGIN}
                data-testid="LoginForm__button"
                fluid
                color="teal"
                size="big"
                className="MailLoginButton__Button"
              >
                メールログインに移動
              </Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <ReturnLink />
    </Container>
  );
};
export default SetPasswordSuccesfully;
