import React from 'react';
import { Card } from 'semantic-ui-react';
import { ProfileState } from 'types';

import InlineLoader from 'components/atoms/InlineLoader';
import ProfileCardElement from '../ProfileCardElement';

type ProfileCardProps = {
  loading: boolean;
  profileData: ProfileState;
};

const ProfileCard: React.FC<ProfileCardProps> = ({ loading, profileData }) => {
  return (
    <Card fluid className="ProfileCard">
      {loading && <InlineLoader />}
      <Card.Content>
        <ProfileCardElement
          testId="ProfileCard__Name"
          label="氏名"
          sub_label="Tên | Name"
          sub_label2=""
          value={profileData.name !== '' ? profileData.name : 'ーーーー'}
        />
        <ProfileCardElement
          testId="ProfileCard__Hired_at"
          label="入社日/契約開始日"
          sub_label="Ngày thuê/Ngày bắt đầu hợp đồng"
          sub_label2="Hire date/Contract start date"
          value={
            profileData.hired_at !== '' ? profileData.hired_at : 'ーーーー'
          }
        />
        <ProfileCardElement
          testId="ProfileCard__Email"
          label="メールアドレス"
          sub_label="Địa chỉ email | E-Mail Address"
          sub_label2=""
          value={profileData.email !== '' ? profileData.email : 'ーーーー'}
        />
        <ProfileCardElement
          testId="ProfileCard__UserID"
          label="UserID"
          sub_label=""
          sub_label2=""
          value={profileData.id !== '' ? profileData.id : 'ーーーー'}
        />
      </Card.Content>
    </Card>
  );
};

export default ProfileCard;
