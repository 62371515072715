import React from 'react';
import { Container } from 'semantic-ui-react';
import LoginButton from 'components/atoms/LoginButton';
import LoginLink from 'components/atoms/LoginLink';
import AppLogo from 'components/atoms/AppLogo';
import { GoogleLoginProps } from 'types';
import { useGoogleLogin } from '@react-oauth/google';

const LoginPage: React.FunctionComponent<GoogleLoginProps> = ({
  loading,
  setGoogleToken,
}) => {
  const login = useGoogleLogin({
    onSuccess: (res) => {
      const token = res.code;
      if (token) {
        setGoogleToken(token);
      }
    },
    flow: 'auth-code',
  });

  return (
    <Container data-testid="LoginPage" className="LoginPage">
      <AppLogo />
      <Container className="LoginPage__formContainer">
        <p className="LoginInformation">
          お知らせメールに記載された方法に従って
          <br />
          ログインしてください。
        </p>
        <LoginButton
          renderProps={{ onClick: () => login(), disabled: loading }}
        />
        <LoginLink />
      </Container>
    </Container>
  );
};

export default LoginPage;
