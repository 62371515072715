import React from 'react';

import { Message } from 'semantic-ui-react';
import { ALERT_STATES } from 'utils/constants';

import './index.css';

type ErrorProps = {
  isPositionRelative?: boolean;
  message: string;
  severity?: 'success' | 'info' | 'warning' | 'error' | undefined;
};

const InfoMessage: React.FC<ErrorProps> = ({
  isPositionRelative,
  message,
  severity,
}) => {
  return (
    <div
      className={`${
        isPositionRelative ? 'InfoMessageWrapperRelative' : 'InfoMessageWrapper'
      }`}
    >
      <Message
        className="InfoMessage"
        success={severity === ALERT_STATES.SUCCESS}
        info={severity === ALERT_STATES.INFO}
        warning={severity === ALERT_STATES.WARNING}
        error={severity === ALERT_STATES.ERROR}
        data-testid="InfoMessage"
        style={{
          textAlign: 'center',
        }}
      >
        {message}
      </Message>
    </div>
  );
};

export default InfoMessage;
