import { useAppContext } from 'containers/App/AppContext';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ResetPasswordType } from 'types';

import Axios from 'utils/axios';
import { ENDPOINTS } from 'utils/constants';
import { errorHandler } from 'utils/helper';

const useSetPassword = (
  initialPasswords?: ResetPasswordType,
  token?: string,
) => {
  const history = useHistory();
  const { setGlobalError } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [successFullResponse, setSuccessFullResponse] = useState<boolean>(
    false,
  );
  const [passwords, setPasswords] = useState<ResetPasswordType>(
    initialPasswords
      ? initialPasswords
      : { password: '', password_confirmation: '' },
  );
  const [fetchingError, setFetchingError] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      if (
        passwords.password === '' ||
        passwords.password_confirmation === '' ||
        token === ''
      )
        return;
      setLoading(true);

      try {
        await Axios.patch(ENDPOINTS.PASSWORD_RESET, {
          ...passwords,
          reset_password_token: token,
        });

        setSuccessFullResponse(true);
        setLoading(false);
      } catch (error) {
        errorHandler(error, setGlobalError);

        setFetchingError(error.toString());
        setLoading(false);
      }
    };

    fetchData();
  }, [passwords, history, setGlobalError, token]);

  return { loading, successFullResponse, setPasswords, fetchingError };
};

export default useSetPassword;
