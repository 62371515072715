import React from 'react';
import { Container } from 'semantic-ui-react';
import ProfileLabel from 'components/atoms/ProfileLabel';
import ProfileValue from 'components/atoms/ProfileValue';

type ProfileCardElementProps = {
  testId?: string;
  label: string;
  sub_label: string;
  sub_label2: string;
  value: string;
};

const ProfileCardElement: React.FunctionComponent<ProfileCardElementProps> = ({
  testId,
  label,
  sub_label,
  sub_label2,
  value,
}) => {
  return (
    <Container data-testid={testId} className="ProfileContainer">
      <ProfileLabel label={label} />
      <ProfileLabel label={sub_label} />
      <ProfileLabel label={sub_label2} />
      <ProfileValue value={value} />
    </Container>
  );
};

export default ProfileCardElement;
