import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import 'index.css';
import { formatDateWithEn, formatBeforeDateWithEn } from 'utils/helper';

type CassetteInfoTransaction = {
  cassette: {
    name: string;
    exists: boolean;
    remainig_points: number;
    maximum_exercise_stocks: number;
    exercise_start_date: string;
    limit_change_date: string;
  };
};
const CassetteInfoTransaction: React.FunctionComponent<CassetteInfoTransaction> = ({
  cassette,
}) => {
  return (
    <Segment data-testid="PointsCassette" className="CassetteInfo" inverted>
      {cassette && (
        <div className="CassetteInfo__cassette">
          <Header
            data-testid="PointsCassette__cassetteHeader"
            className="CassetteInfo__cassetteName"
            color="purple"
          >
            【{cassette.name}】
          </Header>

          <Header data-testid="PointsCassette__cassetteHeader" color="purple">
            <div className="CassetteInfo__Label">
              {formatDateWithEn(cassette.exercise_start_date)}〜
              {formatBeforeDateWithEn(cassette.limit_change_date)}
            </div>
          </Header>

          <Header data-testid="PointsCassette__cassetteHeader" color="purple">
            <div className="CassetteInfo__limitPoints">
              {cassette.maximum_exercise_stocks}
              <span className="CassetteInfo__Subscript">個</span>
            </div>
            <div className="CassetteInfo__Label__small">
              が権利行使可能上限です。
              <span className="CassetteInfo__Subscript__small">※1</span>
            </div>
          </Header>

          <Header data-testid="PointsCassette__cassetteHeader" color="purple">
            <div className="CassetteInfo__Label">
              {formatDateWithEn(cassette.limit_change_date)}〜Dec 6, 2033
              <span className="CassetteInfo__Subscript__small">※2</span>
            </div>
          </Header>

          <Header data-testid="PointsCassette__cassetteHeader" color="purple">
            <div className="CassetteInfo__limitPoints">
              {cassette.remainig_points}
              <span className="CassetteInfo__Subscript">個</span>
            </div>
            <div className="CassetteInfo__Label__small">
              が権利行使可能上限です。
              <span className="CassetteInfo__Subscript__small">※1</span>
            </div>
          </Header>
          <div className="CassetteInfo__notice__center">
            <div className="CassetteInfo__notice__small">
              ※1 ただし、入社日/契約開始日より365日経過
              <br />
              しないと行使できません。
              <br />
              Tuy nhiên, bạn không thể thực hiện được cho
              <br />
              đến khi đủ 365 ngày kể từ ngày vào công
              <br />
              ty/ngày ký hợp đồng chính thức.
              <br />
              However, you can not exercise until 365 days
              <br />
              from the join date/contract date.
              <br />
              ※2 行使期限日 | Thời hạn thực hiện | Exercise
              <br />
              deadline
              <br />
            </div>
            <br />
          </div>
        </div>
      )}
    </Segment>
  );
};

export default CassetteInfoTransaction;
