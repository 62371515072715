import React from 'react';
import { Card, Form, Button } from 'semantic-ui-react';

import { useForm, Controller } from 'react-hook-form';
import InfoMessage from 'components/atoms/InfoMessage';
import {
  INVALID_EMAIL_ERROR_MESSAGE,
  VALID_EMAIL_CHECK_REGEX,
} from 'utils/constants';

//TODO:
//For now I am using !important to get the margins, but it is better to configure it from Semantic UI itself
// as there are a gutter-width and row-margin to consider, I will take a look at it in another PR for improvement
// the quality of the code.

type ResetPasswordFormType = {
  loading: boolean;
  responseMessage: string | undefined;
  setResetEmail: React.Dispatch<React.SetStateAction<string>>;
};

const ResetPasswordForm: React.FC<ResetPasswordFormType> = ({
  loading,
  responseMessage,
  setResetEmail,
}) => {
  const { handleSubmit, errors, control } = useForm({
    mode: 'onChange',
  });

  const submitForm = handleSubmit((values) => {
    setResetEmail(values.email);
  });

  return (
    <Form
      data-testid="ResetPassword"
      className="ResetPassword"
      onSubmit={submitForm}
    >
      <Card fluid className="ResetPassword__email__card">
        <Card.Content>
          {responseMessage && (
            <InfoMessage
              isPositionRelative
              message={responseMessage as string}
              severity={'success'}
            />
          )}

          <Form.Field required className="ResetPassword__email mgBottomBig">
            <label className="LoginForm__label">E-Mail Address</label>

            <Controller
              data-testid="ResetPassword__email"
              control={control}
              as={Form.Input}
              className="FormInput"
              type="email"
              name="email"
              defaultValue=""
              rules={{
                required: '必須項目',
                pattern: {
                  value: VALID_EMAIL_CHECK_REGEX,
                  message: INVALID_EMAIL_ERROR_MESSAGE,
                },
              }}
              error={errors && errors.email && errors.email.message}
            />
            <p className="LoginForm__information">
              指定されたログイン用メールアドレスを
              <br />
              入力してください。
            </p>
          </Form.Field>
        </Card.Content>
      </Card>
      <Button
        loading={loading}
        type="submit"
        data-testid="ResetPassword__button"
        fluid
        circular
        color="teal"
        size="big"
      >
        Send
      </Button>
    </Form>
  );
};

export default ResetPasswordForm;
