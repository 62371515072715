import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import 'index.css';
import { RenderLoginProps } from 'types';

const LoginButton: React.FunctionComponent<RenderLoginProps> = ({
  renderProps,
}) => {
  return (
    <Button
      data-testid="LoginButton"
      className="LoginButton"
      disabled={renderProps && renderProps.disabled}
      onClick={renderProps && renderProps.onClick}
    >
      <Image
        alt="Google Icon"
        className="LoginButton__icon"
        src={`${process.env.PUBLIC_URL}/icons/Google_logo@3x.png`}
        width="30"
      />
      <span>Googleでサインイン</span>
    </Button>
  );
};

export default LoginButton;
