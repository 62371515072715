import React, { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import 'index.css';
import { LANGUAGES } from 'utils/constants';

const Footer: React.FunctionComponent = () => {
  const [language, setLanguage] = useState(LANGUAGES.JAPANESE);
  const handleChangeLanguage = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    language: LANGUAGES,
  ) => {
    e.preventDefault();
    setLanguage(language);
  };
  const determineClassName = (
    current_language: string,
    choice_language: string,
  ) => {
    return (
      current_language !== choice_language &&
      'Footer__GuideLanguage__otherchoice'
    );
  };
  return (
    <Segment data-testid="Footer" className="CassetteInfoFooter">
      <div className="Footer__GuideLanguage">
        <span
          onClick={(e) => handleChangeLanguage(e, LANGUAGES.JAPANESE)}
          className={`Footer__GuideLanguage__choice
          ${determineClassName(language, LANGUAGES.JAPANESE)}
          `}
        >
          日本語
        </span>
        |
        <span
          onClick={(e) => handleChangeLanguage(e, LANGUAGES.VIETNAMESE)}
          className={`Footer__GuideLanguage__choice
            ${determineClassName(language, LANGUAGES.VIETNAMESE)}
          `}
        >
          Việt Nam
        </span>
        |
        <span
          onClick={(e) => handleChangeLanguage(e, LANGUAGES.ENGLISH)}
          className={`Footer__GuideLanguage__choice
            ${determineClassName(language, LANGUAGES.ENGLISH)}
          `}
        >
          English
        </span>
      </div>
      {language === LANGUAGES.JAPANESE && (
        <div className="Footer__Content__jp">
          <p className="CasetteInfo__Footer__sentence">
            ＜ご留意事項＞
            <br />
            1.権利行使は5個(100株)単位。今後株式分割等があった場合は変更の可能性あり
            <br />
            2.雇用契約･業務委託契約等の開始日から1年に満たない場合には当該開始日から1年経過後から行使可能
            <br />
            3.Sun*との契約関係がなくなるとSOポイントと同様に新株予約権も消滅
            <br />
            4.毎年1･4･7･10月の指定期日までに権利行使請求書等を提出し行使代金の払込を行うことにより行使
            <br />
            5.その他詳細は1月中に締結予定の覚書、第1回新株予約権発行要項、SOの手引に従う
            <br />
            6.信託②以降は現時点の想定。信託②の交付時の覚書締結時に決定
            <br />
          </p>
        </div>
      )}
      {language === LANGUAGES.VIETNAMESE && (
        <div className="Footer__Content__vn">
          <p className="CasetteInfo__Footer__sentence">
            ＜Các điểm lưu ý＞
            <br />
            1. Số lượng quyền tối thiểu cho một lần thực hiện là 5 quyền (tương
            ứng 100 cổ phiếu). Số lượng tối thiểu này có thể thay đổi nếu có sự
            chia tách cổ phiếu trong tương lai
            <br />
            2. Trường hợp chưa đủ 01 năm kể từ ngày bắt đầu của Hợp đồng lao
            động, Hợp đồng ủy thác kinh doanh, quyền có thể được thực hiện từ
            thời điểm tròn 01 năm kể từ ngày bắt đầu Hợp đồng.
            <br />
            3. Khi hợp đồng với Sun* kết thúc, điểm SO và quyền mua cổ phiếu sẽ
            bị vô hiệu hóa.
            <br />
            4. Thực hiện quyền bằng cách gửi yêu cầu và chuyển khoản trước thời
            hạn được chỉ định vào tháng 1, 4, 7, 10 hàng năm.
            <br />
            5. Tham khảo Biên bản ghi nhớ được ký kết vào tháng 1, Hướng dẫn
            phát hành quyền mua cổ phiếu lần đầu và Hướng dẫn SO để biết thêm
            các thông tin chi tiết khác
            <br />
            6. Từ Ủy thác ② là giả định tại thời điểm hiện tại, và được xác định
            khi ký kết Biên bản ghi nhớ cấp quyền Ủy thác②.
            <br />
          </p>
        </div>
      )}
      {language === LANGUAGES.ENGLISH && (
        <div className="Footer__Content__en">
          <p className="CasetteInfo__Footer__sentence">
            ＜Notes＞
            <br />
            1. The minimum number of rights for one exercise is 5 rights
            (equivalent to 100 shares). This minimum amount can be changed if
            there is any stock split
            <br />
            2. In case it is less than 01 year since the commencement date of
            the labor contract or business entrustment contract, the right may
            be exercised from the full 01 year time from the date of
            commencement of the contract.
            <br />
            3. When the contract with Sun * ends, SO points and stock options
            will be void.
            <br />
            4. Exercise your rights by submitting a request and making a
            transfer before the deadline specified in January, April, July, and
            October every year.
            <br />
            5. Refer to the Memorandum of Understanding signed in January,
            Guidelines for Initial Equity Issuance and SO Guide for more
            details.
            <br />
            6. From Trust ② is the present assumptions, and shall be determined
            when a memorandum of understanding signed at Trust ② issuance time.
            <br />
          </p>
        </div>
      )}
    </Segment>
  );
};

export default Footer;
