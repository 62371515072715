import React from 'react';
import { Segment } from 'semantic-ui-react';
import 'index.css';

const Massage: React.FunctionComponent = () => {
  return (
    <Segment className="CassetteInfoMassage" inverted>
      各信託より付与されたSOの行使可能な個数については、
      「新株予約権取得に関する覚書」をご確認ください。
      まだ覚書を締結していない場合は、So-fun事務局 （Sun*人事
      小林・是友）にお問い合わせください。
    </Segment>
  );
};

export default Massage;
