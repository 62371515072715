import { DEFAULT_POINT_HISTORY_ID, ERROR_CODES, ROUTES } from './constants';

import { AxiosError } from 'axios';
import { APP_SET_STATE, PointHistories, ExerciseHistories } from 'types';

export const isGuestRoute = (pathname: string) => {
  return (
    pathname === ROUTES.LOGIN ||
    pathname === ROUTES.GUEST_LOGIN ||
    pathname === ROUTES.RESET_PASSWORD ||
    pathname === ROUTES.SET_PASSWORD
  );
};

export const isGuestRouteExceptLogin = (pathname: string) => {
  if (pathname === ROUTES.RESET_PASSWORD || pathname === ROUTES.SET_PASSWORD)
    return true;
};

export const isPrivateRoute = (pathname: string) => {
  if (
    pathname === ROUTES.HOME ||
    pathname === ROUTES.POINTS_HISTORY ||
    pathname === ROUTES.PROFILE
  ) {
    return true;
  }
  return false;
};

export const isKnownRoute = (pathname: string) => {
  if (pathname in ROUTES) return true;
  return false;
};

export const getCurrentDate = () => {
  return new Date().toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const getCurrentDateEn = () => {
  return new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const formatDateWithEn = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const formatBeforeDateWithEn = (date: string) => {
  const yesterday = new Date(date);
  yesterday.setDate(yesterday.getDate() - 1);
  return new Date(yesterday).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const isServerOrAuthorizationError = (
  error: Record<string, string | number>,
) => {
  if (
    [
      ERROR_CODES.FORBIDDEN,
      ERROR_CODES.SERVER_ERROR,
      ERROR_CODES.SERVER_UNAVAILABLE,
      ERROR_CODES.UNAUTHORIZED,
    ].includes(error.statusCode as number)
  ) {
    return true;
  }
  return false;
};

export const errorHandler = (
  error: AxiosError,
  setGlobalError: APP_SET_STATE<Record<string, string | number>>,
) => {
  if (error.response) {
    setGlobalError({
      statusCode: error.response.status,
      errorMessage:
        error.response.data &&
        error.response.data.messages &&
        error.response.data.messages
          ? error.response.data.messages
          : error.toString(),
    });
  } else {
    setGlobalError({
      errorMessage: error.toString(),
    });
  }
};

export const verifyHistoryData = (
  sortedPointsHistoryData: PointHistories[] | undefined,
) => {
  if (
    sortedPointsHistoryData &&
    sortedPointsHistoryData.length !== 0 &&
    sortedPointsHistoryData[0].id !== DEFAULT_POINT_HISTORY_ID
  ) {
    return true;
  } else {
    return false;
  }
};

export const verify_E_HistoryData = (
  sortedExercisesHistoryData: ExerciseHistories[] | undefined,
) => {
  if (
    sortedExercisesHistoryData &&
    sortedExercisesHistoryData.length !== 0 &&
    sortedExercisesHistoryData[0].id !== DEFAULT_POINT_HISTORY_ID
  ) {
    return true;
  } else {
    return false;
  }
};
