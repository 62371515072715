import React, { useState } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import ResetPasswordForm from 'components/molecules/ResetPasswordForm';
import useResetPassword from './useResetPassword';
import ReturnLink from 'components/atoms/ReturnLink';

const ResetPassword: React.FunctionComponent = () => {
  const [resetEmail, setResetEmail] = useState<string>('');
  const { loading, responseMessage } = useResetPassword(resetEmail);

  return (
    <Container data-testid="ResetPasswordPage" className="ResetPassword">
      <Grid padded centered columns={1} verticalAlign="middle">
        <Grid.Row>
          <Grid.Column
            mobile={14}
            tablet={14}
            computer={14}
            largeScreen={14}
            widescreen={14}
          >
            <ResetPasswordForm
              loading={loading}
              responseMessage={responseMessage}
              setResetEmail={setResetEmail}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <ReturnLink />
    </Container>
  );
};

export default ResetPassword;
